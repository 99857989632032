import React, { useState, useEffect, useCallback } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import toast, { Toaster } from 'react-hot-toast';

//JS
import { updateSubTotalSueldoComisionEditar, porcentajeSueldoComisionEditar, getInformacionSueldoComisionEditar } from '../js/liquidacion/sueldoComisionEditar';
import { updateTotalDineroEntregadoEditar, getInformacionDineroEntregadoEditar } from '../js/liquidacion/dineroEntregadoEditar';
import { updateTotalGastosEditar, getInformacionGastosOperadorEditar } from '../js/liquidacion/gastosOperadorEditar';
import { updateTotalCuentasOperadorEditar, getInformacionCuentasOperadorEditar } from '../js/liquidacion/cuentasOperadorEditar';

function EditarLiquidacion({show, handleClose, handleRefetch, rowData}){

    const addFieldsInitialSueldoComisionEditar = useCallback((count = 1) => {
        const initialFieldsSC = rowData.conceptoSueldoComision.camposAdicionalesSueldoComision.slice(0, count).map((campo) => ({
            fecha: campo.Fecha,
            inicio: campo.Inicio,
            destino: campo.Destino,
            carga: campo.Carga,
            comision: campo.Comision,
            barco: campo.Barco,
            transfer: campo.Transfer,
            flete: campo.Cantidad
        }));
        setExtraFieldsSCEditar(prevFields => [...prevFields, ...initialFieldsSC]);
    }, [rowData]);

    const addFieldsInitialCuentasOperador = useCallback((count = 1) => {
        const initialFieldsCuentasOperador = rowData.conceptosCuentasOperador.camposAdicionalesCuentasOperador.slice(0, count).map((campo) => ({
            descripcion: campo.DescripcionExtraCuentasOperador,
            cantidad: campo.CantidadExtraCuentasOperador
        }));
        setExtraFieldsCuentasOperadorEditar(prevFields => [...prevFields, ...initialFieldsCuentasOperador]);
    }, [rowData]);

    const addFieldsInitialDineroEntregado = useCallback((count = 1) => {
        const initialFieldsDineroEntregado = rowData.conceptosDineroEntregado.camposAdicionalesDineroEntregado.slice(0, count).map((campo) => ({
            fecha: campo.Fecha,
            descripcion: campo.Descripcion,
            cantidad: campo.Cantidad
        }));
        setExtraFieldsDineroEntregadoEditar(prevFields => [...prevFields, ...initialFieldsDineroEntregado]);
    }, [rowData]);

    const addFieldsInitialGastos = useCallback((count = 1) => {
        const initialFieldsGastos = rowData.conceptoGastos.camposAdicionales.slice(0, count).map((campo) => ({
            descripcion: campo.descripcion,
            cantidad: campo.cantidad
        }));
        setExtraFieldsGastosEditar(prevFields => [...prevFields, ...initialFieldsGastos]);
    }, [rowData]);
    
    useEffect(() => {
        if (show){
            obtenerOperadoresRegistrados();
            obtenerTractosRegistrados();
            obtenerCajasRegistrados();
            
            setExtraFieldsSCEditar([]);
            addFieldsInitialSueldoComisionEditar(rowData.conceptoSueldoComision.camposAdicionalesSueldoComision.length);

            setExtraFieldsDineroEntregadoEditar([]);
            addFieldsInitialDineroEntregado(rowData.conceptosDineroEntregado.camposAdicionalesDineroEntregado.length);

            setExtraFieldsGastosEditar([]);
            addFieldsInitialGastos(rowData.conceptoGastos.camposAdicionales.length);

            setExtraFieldsCuentasOperadorEditar([]);
            addFieldsInitialCuentasOperador(rowData.conceptosCuentasOperador.camposAdicionalesCuentasOperador.length);
        }
    }, [show, addFieldsInitialSueldoComisionEditar, addFieldsInitialDineroEntregado, addFieldsInitialGastos, addFieldsInitialCuentasOperador, rowData.conceptoSueldoComision?.camposAdicionalesSueldoComision?.length, rowData.conceptosDineroEntregado?.camposAdicionalesDineroEntregado?.length, rowData.conceptoGastos?.camposAdicionales?.length, rowData.conceptosCuentasOperador?.camposAdicionalesCuentasOperador?.length])

    const [operadores, setOperadores] = useState([]);
    const [tractos, setTractos] = useState([]);
    const [cajas, setCajas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingOperadores, setLoadingOperadores] = useState(false);
    const [loadingTractos, setLoadingTractos] = useState(false);
    const [loadingCajas, setLoadingCajas] = useState(false);

    const [collapseState, setCollapseState] = useState({
        sueldoComision: true,
        dineroEntregado: false,
        gastosOperador: false,
        cuentasOperador: false,
    });

    const toggleCollapse = (section) => {
        setCollapseState(prevState => ({
            ...prevState,
            [section]: !prevState[section]
        }));
    }

    //SUELDO COMISION//////////////////////////////////
    const [extraFieldsSCEditar, setExtraFieldsSCEditar] = useState([]);

    const addExtraFieldSueldoComisionEditar = () => {
        setExtraFieldsSCEditar([...extraFieldsSCEditar, {
            fecha: '',
            inicio: '',
            destino: '',
            carga: '',
            comision: '',
            barco: '',
            transfer: '',
            flete: ''
        }]);
    };

    //ACTUALIZAR EL STATE DE SUELDO COMISION
    const handleFieldChangeSueldoComisionEditar = (index, field, value) => {
        const newFields = [...extraFieldsSCEditar];
        newFields[index][field] = value;
        setExtraFieldsSCEditar(newFields);
    };

    //BORRAR EL STATE DE SUELDO COMISION
    const removeExtraFieldSueldoComisionEditar = (index) => {
        const newFields = extraFieldsSCEditar.filter((_, i) => i !== index);
        setExtraFieldsSCEditar(newFields);
    };

    //DINERO ENTREGADO//////////////////////////////////
    const [extraFieldsDineroEntregadoEditar, setExtraFieldsDineroEntregadoEditar] = useState([]);

    const addExtraFieldDineroEntregadoEditar = () => {
        setExtraFieldsDineroEntregadoEditar([...extraFieldsDineroEntregadoEditar, {
            fecha: '',
            descripcion: '',
            cantidad: ''
        }])
    };

    //ACTUALIZAR EL STATE DE DINERO ENTREGADO
    const handleFieldChangeDineroEntregadoEditar = (index, field, value) => {
        const newFields = [...extraFieldsDineroEntregadoEditar];
        newFields[index][field] = value;
        setExtraFieldsDineroEntregadoEditar(newFields);
    };

    //BORRAR EL STATE DE DINERO ENTREGADO
    const removeExtraFieldDineroEntregadoEditar = (index) => {
        const newFields = extraFieldsDineroEntregadoEditar.filter((_, i) => i !== index);
        setExtraFieldsDineroEntregadoEditar(newFields);
    };

    // GASTOS//////////////////////////////////
    const [extraFieldsGastosEditar, setExtraFieldsGastosEditar] = useState([]);

    const addExtraFieldsGastosEditar = () => {
        setExtraFieldsGastosEditar([...extraFieldsGastosEditar, {
            descripcion: '',
            cantidad: ''
        }])
    };

    //ACTUALIZAR EL STATE DE GASTOS
    const handleFieldChangeGastosEditar = (index, field, value) => {
        const newFields = [...extraFieldsGastosEditar];
        newFields[index][field] = value;
        setExtraFieldsGastosEditar(newFields);
    };

    //BORRAR EL STATE DE GASTOS
    const removeExtraFieldsGastosEditar = (index) => {
        const newFields = extraFieldsGastosEditar.filter((_, i) => i !== index);
        setExtraFieldsGastosEditar(newFields);
    }

    //CUENTAS CON OPERADOR//////////////////////////////////
    const [extraFieldsCuentasOperadorEditar, setExtraFieldsCuentasOperadorEditar] = useState([]);

    const addExtraFieldCuentasOperadorEditar = () => {
        setExtraFieldsCuentasOperadorEditar([...extraFieldsCuentasOperadorEditar, {
            descripcion: '',
            cantidad: ''
        }]);
    }

    //ACTUALIZAR EL STATE DE CUENTAS CON OPERADOR
    const handleFieldChangeCuentasOperadorEditar = (index, field, value) => {
        const newFields = [...extraFieldsCuentasOperadorEditar];
        newFields[index][field] = value;
        setExtraFieldsCuentasOperadorEditar(newFields);
    };

    //BORRAR EL STATE DE CUENTAS CON OPERADOR
    const removeExtraFieldsCuentasOperadorEditar = (index) => {
        const newFields = extraFieldsCuentasOperadorEditar.filter((_, i) => i !== index);
        setExtraFieldsCuentasOperadorEditar(newFields);
    };

    useEffect(() => {
        if (show){
            updateSubTotalSueldoComisionEditar();
        }
    }, [show, extraFieldsSCEditar]);

    useEffect(() => {
        if (show){
            updateTotalDineroEntregadoEditar();
        }
    }, [show, extraFieldsDineroEntregadoEditar]);

    useEffect(() => {
        if (show){
            updateTotalGastosEditar();
        }
    }, [show, extraFieldsGastosEditar]);

    useEffect(() => {
        if (show){
            updateTotalCuentasOperadorEditar();
        }
    }, [show, extraFieldsCuentasOperadorEditar]);

    const enviarEdicionLiquidacion = async (event) => {
        event.preventDefault();
        var idLiquidacionActual = document.getElementById("idLiquidacionActual").value;
        var idEditarLiquidacion = document.getElementById("idEditarLiquidacion").value;
        var nombreEditarOperador = document.getElementById("nombreEditarOperador").value;
        var tractoEditarEconomico = document.getElementById("tractoEditarEconomico").value;
        var cajaEditarEconomico = document.getElementById("cajaEditarEconomico").value;
        var porcentajeAmountSueldoComisionEditar = document.getElementById("porcentajeAmountSueldoComisionEditar").value;

        if (idEditarLiquidacion === ""){
            toast.error('INGRESE UN # DE LIQUIDACION', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }

        if (nombreEditarOperador === ""){
            toast.error('SELECCIONE UN OPERADOR', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }

        if (tractoEditarEconomico === ""){
            toast.error('SELECCIONE UN TRACTO', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }

        if (cajaEditarEconomico === ""){
            toast.error('SELECCIONE UNA CAJA', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }

        //SUELDO-COMISION
        var informacionSueldoComision = getInformacionSueldoComisionEditar();
        // if (informacionSueldoComision.success === false) {
        //     toast.error(informacionSueldoComision.error, {
        //         style: {
        //             padding: '16px',
        //             color: 'var(--text-color)',
        //             backgroundColor: 'var(--bg-color)',
        //         }
        //     });
        //     return;
        // }
        var informacionSueldoComisionJSON = JSON.stringify(informacionSueldoComision);

        //DINERO ENTREGADO
        var informacionDineroEntregado = getInformacionDineroEntregadoEditar();
        // if (informacionDineroEntregado.success === false) {
        //     toast.error(informacionDineroEntregado.error, {
        //         style: {
        //             padding: '16px',
        //             color: 'var(--text-color)',
        //             backgroundColor: 'var(--bg-color)',
        //         }
        //     });
        //     return;
        // }
        var informacionDineroEntregadoJSON = JSON.stringify(informacionDineroEntregado);

        if (porcentajeAmountSueldoComisionEditar === ""){
            toast.error('INGRESE EL PORCENTAJE DEL FLETE', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }

        //INFORMACION OPERADOR
        var informacionOperador = {
            idLiquidacionActual: idLiquidacionActual,
            idEditarLiquidacion: idEditarLiquidacion,
            operador: nombreEditarOperador,
            tracto: tractoEditarEconomico,
            caja: cajaEditarEconomico,
            porcentajeSueldoComision: porcentajeAmountSueldoComisionEditar
        }
        var informacionOperadorJSON = JSON.stringify(informacionOperador);

        //GASTOS
        var informacionGastos = getInformacionGastosOperadorEditar();
        // if (informacionGastos.success === false) {
        //     toast.error(informacionGastos.error, {
        //         style: {
        //             padding: '16px',
        //             color: 'var(--text-color)',
        //             backgroundColor: 'var(--bg-color)',
        //         }
        //     });
        //     return;
        // }
        var informacionGastosJSON = JSON.stringify(informacionGastos);

        //CUENTAS OPERADOR
        var informacionCuentasOperador = getInformacionCuentasOperadorEditar();
        // if (informacionCuentasOperador.success === false) {
        //     toast.error(informacionCuentasOperador.error, {
        //         style: {
        //             padding: '16px',
        //             color: 'var(--text-color)',
        //             backgroundColor: 'var(--bg-color)',
        //         }
        //     });
        //     return;
        // }
        var informacionCuentasOperadorJSON = JSON.stringify(informacionCuentasOperador);

        try {
            setLoading(true);
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_update/ActualizarLiquidacion.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    informacionOperador: informacionOperadorJSON,
                    informacionSueldoComision: informacionSueldoComisionJSON,
                    informacionDineroEntregado: informacionDineroEntregadoJSON,
                    informacionGastos: informacionGastosJSON, 
                    informacionCuentasOperador: informacionCuentasOperadorJSON
                })
            });

            const data = await response.json();
    
            if (data.success) {
                setLoading(false);
                toast.success("LIQUIDACION #"+idLiquidacionActual+ " ACTUALIZADA CORRECTAMENTE", {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
                handleRefetch();
                handleCloseFunction();
            } else {
                setLoading(false);
                toast.error(data.error, {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
                // console.log("ERROR AL REGISTRAR LA NUEVA LIQUIDACION");
            }
        } catch (error) {
            setLoading(false);
            console.error('Error al conectar con el servidor:', error);
        }

    };

    async function obtenerOperadoresRegistrados() {
        setLoadingOperadores(true);
        try {
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_select/OperadoresRegistrados.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();
    
            if (data.success) {
                setLoadingOperadores(false);
                setOperadores(data.operadoresregistrados);
            } else {
                setLoadingOperadores(false);
                // console.log("ERROR AL SELECCIONAR LOS OPERADORES REGISTRADOS");
            }
        } catch (error) {
            console.error('Error al conectar con el servidor:', error);
        }
    }

    async function obtenerTractosRegistrados() {
        try {
            setLoadingTractos(true);
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_select/TractosRegistrados.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();
    
            if (data.success) {
                setLoadingTractos(false);
                setTractos(data.tractosregistrados);
            } else {
                setLoadingTractos(false);
                // console.log("ERROR AL SELECCIONAR LOS TRACTOS REGISTRADOS");
            }
        } catch (error) {
            setLoadingTractos(false);
            console.error('Error al conectar con el servidor:', error);
        }
    }

    async function obtenerCajasRegistrados() {
        try {
            setLoadingCajas(true);
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_select/CajasRegistrados.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();
    
            if (data.success) {
                setLoadingCajas(false);
                setCajas(data.cajasregistrados);
            } else {
                setLoadingCajas(false);
                console.log("ERROR AL SELECCIONAR LAS CAJAS REGISTRADAS");
            }
        } catch (error) {
            setLoadingCajas(false);
            console.error('Error al conectar con el servidor:', error);
        }
    }

    const handleCloseFunction = () => {
        setCollapseState(prevState => ({
            ...prevState,
            sueldoComision: true,
            dineroEntregado: false,
            gastosOperador: false,
            cuentasOperador: false
        }));
        handleClose();
    }

    const [dragging, setDragging] = useState(false);
    
    const handleDragStart = (e, index) => {
        setDragging(true);
        e.dataTransfer.setData('text/plain', index);
    };
    
    const handleDragOver = (e) => {
        e.preventDefault();
    };
    
    const handleDropSCEditar = (e, dropIndex) => {
        e.preventDefault();
        setDragging(false);
        const dragIndex = e.dataTransfer.getData('text/plain');
        
        // Mueve el campo arrastrado a la nueva posición
        const updatedFields = [...extraFieldsSCEditar];
        const [movedField] = updatedFields.splice(dragIndex, 1);
        updatedFields.splice(dropIndex, 0, movedField);
        setExtraFieldsSCEditar(updatedFields);
    };

    const handleDropDineroEntregadoEditar = (e, dropIndex) => {
        e.preventDefault();
        setDragging(false);
        const dragIndex = e.dataTransfer.getData('text/plain');
        
        // Mueve el campo arrastrado a la nueva posición
        const updatedFields = [...extraFieldsDineroEntregadoEditar];
        const [movedField] = updatedFields.splice(dragIndex, 1);
        updatedFields.splice(dropIndex, 0, movedField);
        setExtraFieldsDineroEntregadoEditar(updatedFields);
    };

    const handleDropGastosEditar = (e, dropIndex) => {
        e.preventDefault();
        setDragging(false);
        const dragIndex = e.dataTransfer.getData('text/plain');
        
        // Mueve el campo arrastrado a la nueva posición
        const updatedFields = [...extraFieldsGastosEditar];
        const [movedField] = updatedFields.splice(dragIndex, 1);
        updatedFields.splice(dropIndex, 0, movedField);
        setExtraFieldsGastosEditar(updatedFields);
    };

    const handleDropCuentasOperadorEditar = (e, dropIndex) => {
        e.preventDefault();
        setDragging(false);
        const dragIndex = e.dataTransfer.getData('text/plain');
        
        // Mueve el campo arrastrado a la nueva posición
        const updatedFields = [...extraFieldsCuentasOperadorEditar];
        const [movedField] = updatedFields.splice(dragIndex, 1);
        updatedFields.splice(dropIndex, 0, movedField);
        setExtraFieldsCuentasOperadorEditar(updatedFields);
    };
    
    return(
        <div className='modal-content'>
            <Modal show={show} fullscreen = {true} onHide={handleCloseFunction} backdrop="static" keyboard={false}>
                <Form onSubmit={enviarEdicionLiquidacion}>
                    <Modal.Header closeButton>
                        <Modal.Title>ACTUALIZAR LIQUIDACION #{rowData.nLiquidacion}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: 'calc(100vh - 150px)'}}>
                        <Row>
                            <Col md={2}>
                                <Form.Control type='hidden' className='form-control' id='idLiquidacionActual' defaultValue={rowData.nLiquidacion}></Form.Control>
                                <Form.Label htmlFor="idEditarLiquidacion" className="form-label">N. LIQUIDACION:</Form.Label>
                                <Form.Control type='number' className='form-control' id='idEditarLiquidacion' defaultValue={rowData.nLiquidacion}></Form.Control>
                            </Col>
                            <Col md={4}>
                                <Form.Label htmlFor="nombreEditarOperador" className="form-label">NOMBRE DEL OPERADOR:</Form.Label>
                                <Form.Select  type='text' className='form-control' id='nombreEditarOperador' defaultValue={rowData.nombreOp} >
                                {
                                    loadingOperadores ? 
                                    <option selected disabled value="">CARGANDO...</option>
                                    :
                                    <>
                                    <option disabled value="">NOMBRE DEL OPERADOR</option>
                                    {operadores.map((operador, index) => (
                                        <option key={index} value={operador.nombre}>{operador.nombre}</option>
                                    ))}
                                    </>
                                }
                                </Form.Select>
                            </Col>
                            <Col md={3}>
                                <Form.Label htmlFor="tractoEditarEconomico" className="form-label">ECONOMICO TRACTO:</Form.Label>
                                <Form.Select  type='text' className='form-control' id='tractoEditarEconomico' defaultValue={rowData.tracto} >
                                    {
                                        loadingTractos ?
                                        <option selected disabled value="">CARGANDO...</option>
                                        :
                                        <>
                                        <option disabled value="">ECONOMICO TRACTO</option>
                                        {tractos.map((tracto, index) => (
                                            <option key={index} value={tracto.economico}>{tracto.economico}</option>
                                        ))}
                                        </>
                                    }
                                </Form.Select>
                            </Col>
                            <Col md={3}>
                                <Form.Label htmlFor="cajaEditarEconomico" className="form-label">ECONOMICO CAJA:</Form.Label>
                                <Form.Select  type='text' className='form-control' id='cajaEditarEconomico' defaultValue={rowData.caja} >
                                    {
                                        loadingCajas ?
                                        <option selected disabled value="">CARGANDO...</option>
                                        :
                                        <>
                                        <option disabled value="">ECONOMICO CAJA</option>
                                        {cajas.map((caja, index) => (
                                            <option key={index} value={caja.economico}>{caja.economico}</option>
                                        ))}
                                        </>
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <hr style={{border: "1px solid #ffff"}} />

                        <div className="row menuColaps">
                            <div className="col-md-12">
                                <button className="btn btn-primary" style={{border: collapseState.sueldoComision ? 'var(--main-color) solid 1px !important' : 'none' }}  type="button" data-bs-toggle="collapse" data-bs-target="#sueldo-comisionEditar" aria-expanded="false" aria-controls="collapseExample" onClick={() => toggleCollapse('sueldoComision')}>
                                    SUELDO-COMISION
                                </button>
                                <button className="btn btn-primary" style={{border: collapseState.dineroEntregado ? 'var(--main-color) solid 1px !important' : 'none' }} type="button" data-bs-toggle="collapse" data-bs-target="#dineroEntregadoEditar" aria-expanded="false" aria-controls="collapseExample" onClick={() => toggleCollapse('dineroEntregado')}>
                                    DINERO ENTREGADO
                                </button>
                                <button className="btn btn-primary" style={{border: collapseState.gastosOperador ? 'var(--main-color) solid 1px !important' : 'none' }} type="button" data-bs-toggle="collapse" data-bs-target="#gastosOperadorEditar" aria-expanded="false" aria-controls="collapseExample" onClick={() => toggleCollapse('gastosOperador')}>
                                    GASTOS
                                </button>
                                <button className="btn btn-primary" style={{border: collapseState.cuentasOperador ? 'var(--main-color) solid 1px !important' : 'none' }} type="button" data-bs-toggle="collapse" data-bs-target="#cuentasOperadorEditar" aria-expanded="false" aria-controls="collapseExample" onClick={() => toggleCollapse('cuentasOperador')}>
                                    CUENTAS CON OPERADOR
                                </button>
                            </div>
                        </div>

                        {/* EDITAR SUELDO-COMISION */}
                        <div className="collapse show" id="sueldo-comisionEditar">
                            <div id="conceptList">
                                <Card style={{marginTop: "10px"}}>
                                    <Card.Header>
                                        <h5 className='text-center'>SUELDO - COMISION</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className='text-center'>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="fechaSueldoComisionEditar" className="form-label">FECHA:</Form.Label>
                                                <Form.Control type='date' className='form-control' id='fechaSueldoComisionEditar' defaultValue={rowData.conceptoSueldoComision?.fechaSueldoComision}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="inicioSueldoComisionEditar" className="form-label">INICIO:</Form.Label>
                                                <Form.Control type='text' className='form-control' id='inicioSueldoComisionEditar' defaultValue={rowData.conceptoSueldoComision?.inicioSueldoComision}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="destinoSueldoComisionEditar" className="form-label">DESTINO:</Form.Label>
                                                <Form.Control type='text' className='form-control' id='destinoSueldoComisionEditar' defaultValue={rowData.conceptoSueldoComision?.destinoSueldoComision}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="cargaSueldoComisionEditar" className="form-label">CARGA:</Form.Label>
                                                <Form.Control type='text' className='form-control' id='cargaSueldoComisionEditar' defaultValue={rowData.conceptoSueldoComision?.cargaSueldoComision}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="comisionSueldoComisionEditar" className="form-label">$ COMISION:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='comisionSueldoComisionEditar' defaultValue={rowData.conceptoSueldoComision?.comisionSueldoComision} placeholder='$' onClick={() => updateSubTotalSueldoComisionEditar()} onInput={() => updateSubTotalSueldoComisionEditar()}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="barcoSueldoComisionEditar" className="form-label">$ BARCO:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='barcoSueldoComisionEditar' defaultValue={rowData.conceptoSueldoComision?.barcoSueldoComision} placeholder='$' onClick={() => updateSubTotalSueldoComisionEditar()} onInput={() => updateSubTotalSueldoComisionEditar()}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="transferSueldoComisionEditar" className="form-label">$ TRANSFER:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='transferSueldoComisionEditar' defaultValue={rowData.conceptoSueldoComision?.transferSueldoComision} placeholder='$' onClick={() => updateSubTotalSueldoComisionEditar()} onInput={() => updateSubTotalSueldoComisionEditar()}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="amountSueldoComisionEditar" className="form-label">$ FLETE:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='amountSueldoComisionEditar' defaultValue={rowData.conceptoSueldoComision?.amountSueldoComision} placeholder='$' onClick={() => updateSubTotalSueldoComisionEditar()} onInput={() => updateSubTotalSueldoComisionEditar()}></Form.Control>
                                            </Col>
                                        </Row>
                                        <hr style={{border: "1px solid #ffff"}} />
                                        <div id="extraFieldsSueldoComisionEditar">
                                            {extraFieldsSCEditar.map((field, index) => (
                                                <div id = {"campoSCEditar_"+index} key={index} draggable="true" onDragStart={(e) => handleDragStart(e, index)} onDragOver={handleDragOver} onDrop={(e) => handleDropSCEditar(e, index)} style={{ border: dragging ? '1px dashed #000' : 'none' }}>
                                                    <Row className='text-center'>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor={`fechaSueldoComision${index}`} className="form-label">FECHA:</Form.Label>
                                                            <Form.Control type='date' className='form-control' id={`fechaSueldoComisionEditar${index}`} value={field.fecha} onChange={(e) => handleFieldChangeSueldoComisionEditar(index, "fecha", e.target.value)}></Form.Control>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor={`inicioSueldoComision${index}`} className="form-label">INICIO:</Form.Label>
                                                            <Form.Control type='text' className='form-control' id={`inicioSueldoComisionEditar${index}`} value = {field.inicio} onChange={(e) => handleFieldChangeSueldoComisionEditar(index, "inicio", e.target.value)}></Form.Control>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor={`destinoSueldoComision${index}`} className="form-label">DESTINO:</Form.Label>
                                                            <Form.Control type='text' className='form-control' id={`destinoSueldoComisionEditar${index}`} value = {field.destino} onChange={(e) => handleFieldChangeSueldoComisionEditar(index, "destino", e.target.value)}></Form.Control>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor={`cargaSueldoComision${index}`} className="form-label">CARGA:</Form.Label>
                                                            <Form.Control type='text' className='form-control' id={`cargaSueldoComisionEditar${index}`} value = {field.carga} onChange={(e) => handleFieldChangeSueldoComisionEditar(index, "carga", e.target.value)}></Form.Control>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor={`comisionSueldoComision${index}`} className="form-label">$ COMISION:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`comisionSueldoComisionEditar${index}`} value={field.comision} onChange={(e) => handleFieldChangeSueldoComisionEditar(index, "comision", e.target.value)} placeholder='$' onClick={() => updateSubTotalSueldoComisionEditar()} onInput={() => updateSubTotalSueldoComisionEditar()}></Form.Control>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor={`barcoSueldoComision${index}`} className="form-label">$ BARCO:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`barcoSueldoComisionEditar${index}`} value={field.barco} onChange={(e) => handleFieldChangeSueldoComisionEditar(index, "barco", e.target.value)} placeholder='$' onClick={() => updateSubTotalSueldoComisionEditar()} onInput={() => updateSubTotalSueldoComisionEditar()}></Form.Control>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor={`transferSueldoComision${index}`} className="form-label">$ TRANSFER:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`transferSueldoComisionEditar${index}`} value={field.transfer} onChange={(e) => handleFieldChangeSueldoComisionEditar(index, "transfer", e.target.value)} placeholder='$' onClick={() => updateSubTotalSueldoComisionEditar()} onInput={() => updateSubTotalSueldoComisionEditar()}></Form.Control>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor={`amountSueldoComision${index}`} className="form-label">$ FLETE:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`amountSueldoComisionEditar${index}`} value = {field.flete} onChange={(e) => handleFieldChangeSueldoComisionEditar(index, "flete", e.target.value)} placeholder='$' onClick={() => updateSubTotalSueldoComisionEditar()} onInput={() => updateSubTotalSueldoComisionEditar()}></Form.Control>
                                                        </Col>
                                                    </Row>
                                                    <button type="button" className="btn btn-sm btn-danger mt-1" onClick={() => removeExtraFieldSueldoComisionEditar(index)}>ELIMINAR</button>
                                                    <hr style={{border: "1px solid #ffff"}} />
                                                </div>
                                            ))}
                                        </div>
                                        <Button variant='info' style = {{marginTop: '10px', color: '#fff'}} onClick={() => addExtraFieldSueldoComisionEditar()}>CAMPO ADICIONAL</Button>

                                        <Col md = {2} className='text-center' style={{marginLeft: "84%"}}>
                                            <Form.Label htmlFor="subTotalAmountSueldoComisionEditar" className="form-label">$ SUBTOTAL:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='subTotalAmountSueldoComisionEditar' placeholder='$' readOnly></Form.Control>
                                        </Col>
                                        <Col md = {2} className='text-center' style={{marginLeft: "84%"}}>
                                            <Form.Label htmlFor="porcentajeAmountSueldoComisionEditar" className="form-label">% FLETE:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='porcentajeAmountSueldoComisionEditar'  placeholder='%' defaultValue={rowData.porcentajeSueldoComision} onChange={() => porcentajeSueldoComisionEditar()} onInput={() => porcentajeSueldoComisionEditar()}></Form.Control>
                                        </Col>
                                        <Col md = {2} className='text-center' style={{marginLeft: "84%"}}>
                                            <Form.Label htmlFor="totalAmountSueldoComisionEditar" className="form-label">$ TOTAL:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='totalAmountSueldoComisionEditar' placeholder='$' defaultValue={rowData.totalSueldoComision} readOnly></Form.Control>
                                        </Col>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                        {/* EDITAR DINERO ENTREGADO */}
                        <div className="collapse" id="dineroEntregadoEditar">
                            <div id="conceptList">
                                <Card style={{marginTop: "10px"}}>
                                    <Card.Header>
                                        <h5 className='text-center'>DINERO ENTREGADO AL OPERADOR</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className='text-center'>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="fechaDineroEntregadoEditar" className="form-label">FECHA:</Form.Label>
                                                <Form.Control type='date' className='form-control' id='fechaDineroEntregadoEditar' defaultValue={rowData.conceptosDineroEntregado?.fechaDineroEntregado}></Form.Control>
                                            </Col>
                                            <Col md = {8}>
                                                <Form.Label htmlFor="descriptionDineroEntregadoEditar" className="form-label">DESCRIPCION:</Form.Label>
                                                <Form.Control type='text' className='form-control' id='descriptionDineroEntregadoEditar' defaultValue={rowData.conceptosDineroEntregado?.descriptionDineroEntregado}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="amountDineroEntregadoEditar" className="form-label">$ CANTIDAD:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='amountDineroEntregadoEditar' defaultValue={rowData.conceptosDineroEntregado?.amountDineroEntregado} placeholder='$' onClick={() => updateTotalDineroEntregadoEditar()} onInput={() => updateTotalDineroEntregadoEditar()}></Form.Control>
                                            </Col>
                                        </Row>
                                        <hr style={{border: "1px solid #ffff"}} />
                                        <div id="extraFieldsDineroEntregadoEditar">
                                            {extraFieldsDineroEntregadoEditar.map((field, index) => (
                                                <div id = {"campoDEEditar_"+index} key={index} draggable="true" onDragStart={(e) => handleDragStart(e, index)} onDragOver={handleDragOver} onDrop={(e) => handleDropDineroEntregadoEditar(e, index)} style={{ border: dragging ? '1px dashed #000' : 'none' }}>
                                                    <Row className='text-center'>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`fechaDineroEntregadoEditar${index}`} className="form-label">FECHA:</Form.Label>
                                                            <Form.Control type='date' className='form-control' id={`fechaDineroEntregadoEditar${index}`} value={field.fecha} onChange={(e) => handleFieldChangeDineroEntregadoEditar(index, 'fecha', e.target.value)}></Form.Control>
                                                        </Col>
                                                        <Col md = {8}>
                                                            <Form.Label htmlFor={`descriptionDineroEntregadoEditar${index}`} className="form-label">DESCRIPCION:</Form.Label>
                                                            <Form.Control type='text' className='form-control' id={`descriptionDineroEntregadoEditar${index}`} value={field.descripcion} onChange={(e) => handleFieldChangeDineroEntregadoEditar(index, 'descripcion', e.target.value)}></Form.Control>
                                                        </Col>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`amountDineroEntregadoEditar${index}`} className="form-label">$ CANTIDAD:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`amountDineroEntregadoEditar${index}`} value={field.cantidad} onChange={(e) => handleFieldChangeDineroEntregadoEditar(index, 'cantidad', e.target.value)} placeholder='$' onClick={() => updateTotalDineroEntregadoEditar()} onInput={() => updateTotalDineroEntregadoEditar()}></Form.Control>
                                                        </Col>
                                                    </Row>
                                                    <button type="button" className="btn btn-sm btn-danger mt-1" onClick={() => removeExtraFieldDineroEntregadoEditar(index)}>ELIMINAR</button>
                                                    <hr style={{border: "1px solid #ffff"}} />
                                                </div>
                                            ))}
                                        </div>
                                        <Button variant='info' style = {{marginTop: '10px', color: '#fff'}} onClick={() => addExtraFieldDineroEntregadoEditar()}>CAMPO ADICIONAL</Button>

                                        <Col md = {2} className='text-center' style={{marginLeft: '84%'}}>
                                            <Form.Label htmlFor="totalAmountDineroEntregadoEditar" className="form-label">$ TOTAL:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='totalAmountDineroEntregadoEditar' defaultValue={rowData.totalDineroEntregado} readOnly placeholder='$'></Form.Control>
                                        </Col>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                        {/* EDITAR GASTOS */}
                        <div className="collapse" id="gastosOperadorEditar">
                            <div id="conceptList">
                                <Card style={{marginTop: "10px"}}>
                                    <Card.Header>
                                        <h5 className='text-center'>GASTOS</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className='text-center'>
                                            <Col md = {10}>
                                                <Form.Label htmlFor="descriptionEditar" className="form-label">DESCRIPCION:</Form.Label>
                                                <Form.Control type='text' className='form-control' id='descriptionEditar' defaultValue={rowData.conceptoGastos?.descripcion}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="amountEditar" className="form-label">$ CANTIDAD:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='amountEditar' defaultValue={rowData.conceptoGastos?.cantidad} placeholder='$' onClick={() => updateTotalGastosEditar()} onInput={() => updateTotalGastosEditar()}></Form.Control>
                                            </Col>
                                        </Row>
                                        <hr style={{border: "1px solid #ffff"}} />
                                        <div id="extraFieldsGastosEditar">
                                            {extraFieldsGastosEditar.map((field, index) => (
                                                <div id = {"campoGEditar_"+index} key={index} draggable="true" onDragStart={(e) => handleDragStart(e, index)} onDragOver={handleDragOver} onDrop={(e) => handleDropGastosEditar(e, index)} style={{ border: dragging ? '1px dashed #000' : 'none' }}>
                                                    <Row className='text-center'>
                                                        <Col md = {10}>
                                                            <Form.Label htmlFor = {`descriptionEditar${index}`} className="form-label">DESCRIPCION:</Form.Label>
                                                            <Form.Control type='text' className='form-control' id={`descriptionEditar${index}`} value={field.descripcion} onChange={(e) => handleFieldChangeGastosEditar(index, "descripcion", e.target.value)}></Form.Control>
                                                        </Col>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`amountEditar${index}`} className="form-label">$ CANTIDAD:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`amountEditar${index}`} value={field.cantidad} onChange={(e) => handleFieldChangeGastosEditar(index, "cantidad", e.target.value)} placeholder='$' onClick={() => updateTotalGastosEditar()} onInput={() => updateTotalGastosEditar()}></Form.Control>
                                                        </Col>
                                                    </Row>
                                                    <button type="button" className="btn btn-sm btn-danger mt-1" onClick={() => removeExtraFieldsGastosEditar(index)}>ELIMINAR</button>
                                                    <hr style={{border: "1px solid #ffff"}} />
                                                </div>
                                            ))}
                                        </div>
                                        <Button variant='info' style = {{marginTop: '10px', color: '#fff'}} onClick={() => addExtraFieldsGastosEditar()}>CAMPO ADICIONAL</Button>

                                        <Col md = {2} className='text-center' style={{marginLeft: '84%'}}>
                                            <Form.Label htmlFor="totalAmountGastosEditar" className="form-label">$ TOTAL:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='totalAmountGastosEditar' defaultValue={rowData.totalGastos} readOnly placeholder='$'></Form.Control>
                                        </Col>

                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                        {/* EDITAR CUENTAS CON OPERADOR */}
                        <div className="collapse" id="cuentasOperadorEditar">
                            <div id="conceptList">
                            <Card style={{marginTop: "10px"}}>
                                <Card.Header>
                                    <h5 className='text-center'>CUENTAS CON OPERADOR</h5>
                                </Card.Header>
                                <Card.Body>
                                    <Row className='text-center'>
                                        <Col md = {10}>
                                            <Form.Label htmlFor="descriptionCuentasOperador1Editar" className="form-label">DESCRIPCION:</Form.Label>
                                            <Form.Control type='text' className='form-control' id='descriptionCuentasOperador1Editar' defaultValue={"DINERO ENTREGADO"} readOnly></Form.Control>
                                        </Col>
                                        <Col md = {2}>
                                            <Form.Label htmlFor="amountCuentasOperador1Editar" className="form-label">$ CANTIDAD:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='amountCuentasOperador1Editar' defaultValue={""} readOnly placeholder='$' onChange={() => updateTotalCuentasOperadorEditar()} onInput={() => updateTotalCuentasOperadorEditar()}></Form.Control>
                                        </Col>
                                    </Row>
                                    <hr style={{border: "1px solid #ffff"}} />
                                    <Row className='text-center'>
                                        <Col md = {10}>
                                            <Form.Label htmlFor="descriptionCuentasOperador2Editar" className="form-label">DESCRIPCION:</Form.Label>
                                            <Form.Control type='text' className='form-control' id='descriptionCuentasOperador2Editar' defaultValue={"GASTOS QUE HIZO"} readOnly></Form.Control>
                                        </Col>
                                        <Col md = {2}>
                                            <Form.Label htmlFor="amountCuentasOperador2Editar" className="form-label">$ CANTIDAD:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='amountCuentasOperador2Editar' defaultValue={""} readOnly placeholder='$' onChange={() => updateTotalCuentasOperadorEditar()} onInput={() => updateTotalCuentasOperadorEditar()}></Form.Control>
                                        </Col>
                                    </Row>
                                    <hr style={{border: "1px solid #ffff"}} />
                                    <Row className='text-center'>
                                        <Col md = {10}>
                                            <Form.Label htmlFor="descriptionCuentasOperador3Editar" className="form-label">DESCRIPCION:</Form.Label>
                                            <Form.Control type='text' className='form-control' id='descriptionCuentasOperador3Editar' defaultValue={"LE SOBRO Y SE ABONO A SU SUELDO"} readOnly></Form.Control>
                                        </Col>
                                        <Col md = {2}>
                                            <Form.Label htmlFor="amountCuentasOperador3Editar" className="form-label">$ CANTIDAD:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='amountCuentasOperador3Editar' defaultValue={""} readOnly placeholder='$' onChange={() => updateTotalCuentasOperadorEditar()} onInput={() => updateTotalCuentasOperadorEditar()}></Form.Control>
                                        </Col>
                                    </Row>
                                    <hr style={{border: "1px solid #ffff"}} />
                                    <Row className='text-center'>
                                        <Col md = {10}>
                                            <Form.Label htmlFor="descriptionCuentasOperador4Editar" className="form-label">DESCRIPCION:</Form.Label>
                                            <Form.Control type='text' className='form-control' id='descriptionCuentasOperador4Editar' defaultValue={"SUELDO - COMISION"} readOnly></Form.Control>
                                        </Col>
                                        <Col md = {2}>
                                            <Form.Label htmlFor="amountCuentasOperador4Editar" className="form-label">$ CANTIDAD:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='amountCuentasOperador4Editar' defaultValue={""} readOnly placeholder='$' onChange={() => updateTotalCuentasOperadorEditar()} onInput={() => updateTotalCuentasOperadorEditar()}></Form.Control>
                                        </Col>
                                    </Row>
                                    <hr style={{border: "1px solid #ffff"}} />
                                    <div id="extraFieldsCuentasOperadorEditar">
                                        {extraFieldsCuentasOperadorEditar.map((field, index) => (
                                            <div id = {"campoCCPEditar_"+index} key={index} draggable="true" onDragStart={(e) => handleDragStart(e, index)} onDragOver={handleDragOver} onDrop={(e) => handleDropCuentasOperadorEditar(e, index)} style={{ border: dragging ? '1px dashed #000' : 'none' }}>
                                                <Row className='text-center'>
                                                    <Col md = {10}>
                                                        <Form.Label htmlFor={`descriptionCuentasOperadorEditar${index}`} className="form-label">DESCRIPCION:</Form.Label>
                                                        <Form.Control type='text' className='form-control' id={`descriptionCuentasOperadorEditar${index}`} value={field.descripcion} onChange={(e) => handleFieldChangeCuentasOperadorEditar(index, "descripcion", e.target.value)}></Form.Control>
                                                    </Col>
                                                    <Col md = {2}>
                                                        <Form.Label htmlFor={`amountCuentasOperadorEditar${index}`} className="form-label">$ CANTIDAD:</Form.Label>
                                                        <Form.Control type='number' className='form-control' id={`amountCuentasOperadorEditar${index}`} value={field.cantidad} placeholder='$' onChange={(e) => handleFieldChangeCuentasOperadorEditar(index, "cantidad", e.target.value)} onClick={() => updateTotalCuentasOperadorEditar()} onInput={() => updateTotalCuentasOperadorEditar()}></Form.Control>
                                                    </Col>
                                                </Row>
                                                <button type="button" className="btn btn-sm btn-danger mt-1" onClick={() => removeExtraFieldsCuentasOperadorEditar(index)}>ELIMINAR</button>
                                                <hr style={{border: "1px solid #ffff"}} />
                                            </div>
                                        ))}
                                    </div>
                                    <Button variant='info' style = {{marginTop: '10px', color: '#fff'}} onClick={() => addExtraFieldCuentasOperadorEditar()}>CAMPO ADICIONAL</Button>

                                    <Col md = {8} style={{marginTop: '10px'}}>
                                        <Form.Label htmlFor="observacionesCuentasOperadorEditar" className="form-label">OBSERVACIONES:</Form.Label>
                                        <textarea className="form-control" id="observacionesCuentasOperadorEditar" style={{color: '#fff'}} defaultValue={rowData.observacionesCuentasOperador}></textarea>
                                    </Col>

                                    <Col md = {2} className='text-center' style={{marginLeft: '84%'}}>
                                        <Form.Label htmlFor="totalAmountCuentasOperadorEditar" className="form-label">TOTAL A PAGAR:</Form.Label>
                                        <Form.Control type='number' className='form-control' id='totalAmountCuentasOperadorEditar' defaultValue={rowData.totalAPagarOperador} readOnly placeholder='$'></Form.Control>
                                    </Col>
                                </Card.Body>
                            </Card>
                            </div>
                        </div>                   
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleCloseFunction}>CANCELAR</Button>
                        {
                            loading ?
                            <Button disabled className='btnFormularios' variant="primary" type="submit"><span className="spinner-border spinner-border-sm" aria-hidden="true"></span> ACTUALIZANDO LIQUIDACION</Button>
                            :
                            <Button className='btnFormularios' variant="primary" type="submit">EDITAR LIQUIDACION</Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            <Toaster position="top-right" reverseOrder={false}/>
        </div>
    );
}

export default EditarLiquidacion