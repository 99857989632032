//Gastos Regulares
import { updateGastosGeneralesEnLaVuelta } from "./cuentasGenerales";

export function updateTotalGastosRegularesEditar() {
    var GastosRegulares = 0;
    var defaultAmount = document.getElementById("amountGastosRegularesEditar").value;
    GastosRegulares += parseFloat(defaultAmount) || 0;
    var amounts = document.querySelectorAll("#extraFieldsGastosRegulares input[type='number']");
    amounts.forEach(function(amountInput) {
        GastosRegulares += parseFloat(amountInput.value) || 0;
    });
    document.getElementById("totalAmountGastosRegularesEditar").value = GastosRegulares;
    updateGastosGeneralesEnLaVuelta();
}

var camposAdicionaleGastosRegulares = [];
export function getInformacionGastosRegulares(){
    var descripcionGastosRegularesEditar = document.getElementById("descripcionGastosRegularesEditar").value;
    var amountGastosRegularesEditar = document.getElementById("amountGastosRegularesEditar").value;
    var totalAmountGastosRegularesEditar = document.getElementById("totalAmountGastosRegularesEditar").value;

    var descripcionGastosRegularesEditarExtras = document.querySelectorAll("#extraFieldsGastosRegulares input[type='text']");
    var amountGastosRegularesEditarExtras = document.querySelectorAll("#extraFieldsGastosRegulares input[type='number']");

    camposAdicionaleGastosRegulares.length = 0;
    for (let i = 0; i < descripcionGastosRegularesEditarExtras.length; i++) {
        var descripcionGastosRegularesEditarExtra = descripcionGastosRegularesEditarExtras[i].value;
        var amountGastosRegularesEditarExtra = amountGastosRegularesEditarExtras[i].value;

        camposAdicionaleGastosRegulares.push({DescripcionGastosRegularesExtras: descripcionGastosRegularesEditarExtra, CantidadGastosRegularesExtras: amountGastosRegularesEditarExtra });
    }

    // if (descripcionGastosRegularesEditar === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA DESCRIPCION N.1 EN GASTOS REGULARES"
    //     };
    // }

    // if (amountGastosRegularesEditar === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA CANTIDAD N.1 EN GASTOS REGULARES"
    //     };
    // }
    
    // for (let i = 0; i < descripcionGastosRegularesEditarExtras.length; i++) {
    //     if (descripcionGastosRegularesEditarExtras[i].value === "") {
    //         return {
    //             success: false,
    //             error: "INGRESE LA DESCRIPCION N."+(i+2)+" EN GASTOS REGULARES"
    //         };
    //     }

    //     if (amountGastosRegularesEditarExtras[i].value === "") {
    //         return {
    //             success: false,
    //             error: "INGRESE LA CANTIDAD N."+(i+2)+" EN GASTOS REGULARES"
    //         };
    //     }
        
    // }

    var informacionGastosRegulares = {
        descripcionGastosRegulares: descripcionGastosRegularesEditar,
        amountGastosRegulares: amountGastosRegularesEditar,
        camposAdicionaleGastosRegulares: camposAdicionaleGastosRegulares,
        totalAmountGastosRegulares: totalAmountGastosRegularesEditar
    };

    return informacionGastosRegulares;
}