//GASTOS
export function updateTotalGastos() {
    var totalGastos = 0;
    var defaultAmount = document.getElementById("amount").value;
    totalGastos += parseFloat(defaultAmount) || 0;
    var amounts = document.querySelectorAll("#extraFieldsGastos input[type='number']");
    amounts.forEach(function(amountInput) {
        totalGastos += parseFloat(amountInput.value) || 0;
    });
    document.getElementById("totalAmountGastos").value = totalGastos;

    //CUENTAS CON EL OPERADOR
    document.getElementById("amountCuentasOperador2").value = totalGastos;

    var dineroEntregadoOperador = document.getElementById("totalAmountDineroEntregado").value;
    var gastosQueHizoOperador = document.getElementById("totalAmountGastos").value;
    document.getElementById("amountCuentasOperador3").value = dineroEntregadoOperador - gastosQueHizoOperador;

    var sobroAbonoASuSueldo = document.getElementById("amountCuentasOperador3").value;
    var sueldoComisionTotal = document.getElementById("amountCuentasOperador4").value;
    var totalExtrasCuentasOperador = 0;
    var cantidadesExtrasCuentasOperador = document.querySelectorAll("#extraFieldsCuentasOperador input[type='number']");
    cantidadesExtrasCuentasOperador.forEach(function(amountInputCuentasOperador) {
        totalExtrasCuentasOperador += parseFloat(amountInputCuentasOperador.value) || 0;
    });

    document.getElementById("totalAmountCuentasOperador").value = sueldoComisionTotal - sobroAbonoASuSueldo - totalExtrasCuentasOperador;

}

var camposAdicionales = [];
export function getInformacionGastosOperador(){
    var descripcion = document.getElementById("description").value;
    var cantidad = document.getElementById("amount").value;
    var totalGastos = document.getElementById("totalAmountGastos").value;
    var camposExtras = document.querySelectorAll("#extraFieldsGastos input[type='text']");
    var cantidadesExtras = document.querySelectorAll("#extraFieldsGastos input[type='number']");
    camposAdicionales.length = 0;
    
    for (let i = 0; i < camposExtras.length; i++) {
        var campoExtra = camposExtras[i].value;
        var cantidadExtra = cantidadesExtras[i].value;
        camposAdicionales.push({ descripcion: campoExtra, cantidad: cantidadExtra });
    }

    // if (descripcion === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA DESCRIPCION N.1 DE GASTOS"
    //     };
    // }

    // if (cantidad === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA CANTIDAD N.1 DE GASTOS"
    //     };
    // }
    
    // for (let i = 0; i < camposExtras.length; i++) {
    //     if (camposExtras[i].value === ""){
    //         return {
    //             success: false,
    //             error: "INGRESE LA DESCRIPCION N."+(i+2)+" DE GASTOS"
    //         };
    //     }
    
    //     if (cantidadesExtras[i].value === ""){
    //         return {
    //             success: false,
    //             error: "INGRESE LA CANTIDAD N."+(i+2)+" DE GASTOS"
    //         };
    //     }
    // }

    var informacionGastos = {
        descripcion: descripcion,
        cantidad: cantidad,
        camposAdicionales: camposAdicionales,
        totalGastos: totalGastos
    };
    
    return informacionGastos;
}