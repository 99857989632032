//Gastos Regulares

import { updateGastosGeneralesEnLaVuelta } from "./cuentasGenerales";

export function updateTotalOtrosGastosEditar() {
    var OtrosGastos = 0;
    var defaultAmount = document.getElementById("amountOtrosGastosEditar").value;
    OtrosGastos += parseFloat(defaultAmount) || 0;
    var amounts = document.querySelectorAll("#extraFieldsOtrosGastos input[type='number']");
    amounts.forEach(function(amountInput) {
        OtrosGastos += parseFloat(amountInput.value) || 0;
    });
    document.getElementById("totalAmountOtrosGastosEditar").value = OtrosGastos;
    updateGastosGeneralesEnLaVuelta();
}

var camposAdicionaleOtrosGastos = [];
export function getInformacionOtrosGastos(){
    var descripcionOtrosGastosEditar = document.getElementById("descripcionOtrosGastosEditar").value;
    var amountOtrosGastosEditar = document.getElementById("amountOtrosGastosEditar").value;
    var totalAmountOtrosGastosEditar = document.getElementById("totalAmountOtrosGastosEditar").value;

    var descripcionOtrosGastosEditarExtras = document.querySelectorAll("#extraFieldsOtrosGastos input[type='text']");
    var amountOtrosGastosEditarExtras = document.querySelectorAll("#extraFieldsOtrosGastos input[type='number']");

    camposAdicionaleOtrosGastos.length = 0;
    for (let i = 0; i < descripcionOtrosGastosEditarExtras.length; i++) {
        var descripcionOtrosGastosEditarExtra = descripcionOtrosGastosEditarExtras[i].value;
        var amountOtrosGastosEditarExtra = amountOtrosGastosEditarExtras[i].value;

        camposAdicionaleOtrosGastos.push({DescripcionOtrosGastosExtras: descripcionOtrosGastosEditarExtra, CantidadOtrosGastosExtras: amountOtrosGastosEditarExtra });
    }

    // if (descripcionOtrosGastosEditar === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA DESCRIPCION N.1 EN OTROS GASTOS"
    //     };
    // }

    // if (amountOtrosGastosEditar === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA CANTIDAD N.1 EN OTROS GASTOS"
    //     };
    // }
    
    // for (let i = 0; i < descripcionOtrosGastosEditarExtras.length; i++) {
    //     if (descripcionOtrosGastosEditarExtras[i].value === "") {
    //         return {
    //             success: false,
    //             error: "INGRESE LA DESCRIPCION N."+(i+2)+" EN OTROS GASTOS"
    //         };
    //     }

    //     if (amountOtrosGastosEditarExtras[i].value === "") {
    //         return {
    //             success: false,
    //             error: "INGRESE LA CANTIDAD N."+(i+2)+" EN GASTOS REGULARES"
    //         };
    //     }
        
    // }

    var informacionOtrosGastos = {
        descripcionOtrosGastos: descripcionOtrosGastosEditar,
        amountOtrosGastos: amountOtrosGastosEditar,
        camposAdicionaleOtrosGastos: camposAdicionaleOtrosGastos,
        totalAmountOtrosGastos: totalAmountOtrosGastosEditar
    };

    return informacionOtrosGastos;
}