import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import toast, { Toaster } from 'react-hot-toast';

//JS
import { updateSubTotalSueldoComision, porcentajeSueldoComision, getInformacionSueldoComision } from '../js/liquidacion/sueldoComision';
import { updateTotalDineroEntregado, getInformacionDineroEntregado } from '../js/liquidacion/dineroEntregado';
import { updateTotalGastos, getInformacionGastosOperador } from '../js/liquidacion/gastosOperador';
import { updateTotalCuentasOperador, getInformacionCuentasOperador } from '../js/liquidacion/cuentasOperador';

function RegistrarLiquidacion({show, handleClose, handleRefetch}){
    useEffect(() => {
        if (show){
            obtenerSiguienteLiquidacion();
            obtenerOperadoresRegistrados();
            obtenerTractosRegistrados();
            obtenerCajasRegistrados();
        }
    }, [show])

    const [ultimaliquidacion, setUltimaLiquidacion] = useState("");
    const [operadores, setOperadores] = useState([]);
    const [tractos, setTractos] = useState([]);
    const [cajas, setCajas] = useState([]);
    const [loading, setLoading] = useState(false);

    const [collapseState, setCollapseState] = useState({
        sueldoComision: true,
        dineroEntregado: false,
        gastosOperador: false,
        cuentasOperador: false,
    });

    const toggleCollapse = (section) => {
        setCollapseState(prevState => ({
            ...prevState,
            [section]: !prevState[section]
        }));
    }

    //SUELDO COMISION//////////////////////////////////
    const [extraFieldsSC, setExtraFieldsSC] = useState([]);

    const addExtraFieldSueldoComision = () => {
        setExtraFieldsSC([...extraFieldsSC, {
            fecha: '',
            inicio: '',
            destino: '',
            carga: '',
            comision: '',
            barco: '',
            transfer: '',
            flete: ''
        }]);
    };
    
    //ACTUALIZAR EL STATE DE SUELDO COMISION
    const handleFieldChangeSueldoComision = (index, field, value) => {
        const newFields = [...extraFieldsSC];
        newFields[index][field] = value;
        setExtraFieldsSC(newFields);
    };

    //BORRAR EL STATE DE SUELDO COMISION
    const removeExtraFieldSueldoComision = (index) => {
        const newFields = extraFieldsSC.filter((_, i) => i !== index);
        setExtraFieldsSC(newFields);
        updateSubTotalSueldoComision();
    };

    //DINERO ENTREGADO//////////////////////////////////
    const [extraFieldsDineroEntregado, setExtraFieldsDineroEntregado] = useState([]);

    const addExtraFieldDineroEntregado = () => {
        setExtraFieldsDineroEntregado([...extraFieldsDineroEntregado, {
            fecha: '',
            descripcion: '',
            cantidad: ''
        }]);
    };

    //ACTUALIZAR EL STATE DE DINERO ENTREGADO
    const handleFieldChangeDineroEntregado = (index, field, value) => {
        const newFields = [...extraFieldsDineroEntregado];
        newFields[index][field] = value;
        setExtraFieldsDineroEntregado(newFields);
    };

    //BORRAR EL STATE DE DINERO ENTREGADO
    const removeExtraFieldDineroEntregado = (index) => {
        const newFields = extraFieldsDineroEntregado.filter((_, i) => i !== index);
        setExtraFieldsDineroEntregado(newFields);
    }

    // GASTOS//////////////////////////////////
    const [extraFieldsGastos, setExtraFieldsGastos] = useState([]);

    const addExtraFieldGastos = () => {
        setExtraFieldsGastos([...extraFieldsGastos, {
            descripcion: '',
            cantidad: ''
        }]);
    };

    //ACTUALIZAR EL STATE DE GASTOS
    const handleFieldChangeGastos = (index, field, value) => {
        const newFields = [...extraFieldsGastos];
        newFields[index][field] = value;
        setExtraFieldsGastos(newFields);
    };

    //BORRAR EL STATE DE GASTOS
    const removeExtraFieldGastos = (index) => {
        const newFields = extraFieldsGastos.filter((_, i) => i !== index);
        setExtraFieldsGastos(newFields);
    }

    //CUENTAS CON OPERADOR//////////////////////////////////
    const [extraFieldsCuentasConOperador, setExtraFieldsCuentasConOperador] = useState([]);

    const addExtraFieldCuentasOperador = () => {
        setExtraFieldsCuentasConOperador([...extraFieldsCuentasConOperador, {
            descripcion: '',
            cantidad: ''
        }]);
    };

    //ACTUALIZAR EL STATE DE CUENTAS CON OPERADOR
    const handleFieldChangeCuentasOperadorEditar = (index, field, value) => {
        const newFields = [...extraFieldsCuentasConOperador];
        newFields[index][field] = value;
        setExtraFieldsCuentasConOperador(newFields);
    };

    //BORRAR EL STATE DE CUENTAS CON OPERADOR
    const removeExtraFieldCuentasOperador = (index) => {
        const newFields = extraFieldsCuentasConOperador.filter((_, i) => i !== index);
        setExtraFieldsCuentasConOperador(newFields);
    }

    useEffect(() => {
        if (show){
            updateSubTotalSueldoComision();
        }
    }, [show, extraFieldsSC]);

    useEffect(() => {
        if (show){
            updateTotalDineroEntregado();
        }
    }, [show, extraFieldsDineroEntregado]);

    useEffect(() => {
        if (show){
            updateTotalGastos();
        }
    }, [show, extraFieldsGastos]);

    useEffect(() => {
        if (show){
            updateTotalCuentasOperador();
        }
    }, [show, extraFieldsCuentasConOperador]);

    const enviarRegistroLiquidacion = async (event) => {
        event.preventDefault();
        var nLiquidacion = document.getElementById("idLiquidacion").value;
        var operador = document.getElementById("nombreOperador").value;
        var tracto = document.getElementById("tractoEconomico").value;
        var caja = document.getElementById("cajaEconomico").value;
        var porcentajeSueldoComision = document.getElementById("porcentajeAmountSueldoComision").value;

        if (nLiquidacion === ""){
            toast.error('INGRESE UN # DE LIQUIDACION', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }

        if (operador === ""){
            toast.error('SELECCIONE UN OPERADOR', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }

        if (tracto === ""){
            toast.error('SELECCIONE UN TRACTO', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }

        if (caja === ""){
            toast.error('SELECCIONE UNA CAJA', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }

        //SUELDO-COMISION
        var informacionSueldoComision = getInformacionSueldoComision();
        // if (informacionSueldoComision.success === false) {
        //     toast.error(informacionSueldoComision.error, {
        //         style: {
        //             padding: '16px',
        //             color: 'var(--text-color)',
        //             backgroundColor: 'var(--bg-color)',
        //         }
        //     });
        //     return;
        // }
        var informacionSueldoComisionJSON = JSON.stringify(informacionSueldoComision);

        if (porcentajeSueldoComision === ""){
            toast.error('INGRESE EL PORCENTAJE DEL FLETE', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }

        //INFORMACION OPERADOR
        var informacionOperador = {
            nLiquidacion: nLiquidacion,
            operador: operador,
            tracto: tracto,
            caja: caja,
            porcentajeSueldoComision: porcentajeSueldoComision
        }
        var informacionOperadorJSON = JSON.stringify(informacionOperador);

        //DINERO ENTREGADO
        var informacionDineroEntregado = getInformacionDineroEntregado();
        // if (informacionDineroEntregado.success === false) {
        //     toast.error(informacionDineroEntregado.error, {
        //         style: {
        //             padding: '16px',
        //             color: 'var(--text-color)',
        //             backgroundColor: 'var(--bg-color)',
        //         }
        //     });
        //     return;
        // }
        var informacionDineroEntregadoJSON = JSON.stringify(informacionDineroEntregado);

        //GASTOS
        var informacionGastos = getInformacionGastosOperador();
        // if (informacionGastos.success === false) {
        //     toast.error(informacionGastos.error, {
        //         style: {
        //             padding: '16px',
        //             color: 'var(--text-color)',
        //             backgroundColor: 'var(--bg-color)',
        //         }
        //     });
        //     return;
        // }
        var informacionGastosJSON = JSON.stringify(informacionGastos);

        //CUENTAS OPERADOR
        var informacionCuentasOperador = getInformacionCuentasOperador();
        // if (informacionCuentasOperador.success === false) {
        //     toast.error(informacionCuentasOperador.error, {
        //         style: {
        //             padding: '16px',
        //             color: 'var(--text-color)',
        //             backgroundColor: 'var(--bg-color)',
        //         }
        //     });
        //     return;
        // }
        var informacionCuentasOperadorJSON = JSON.stringify(informacionCuentasOperador);
        
        try {
            setLoading(true);
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_insert/InsertarLiquidacion.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    informacionOperador: informacionOperadorJSON,
                    informacionSueldoComision: informacionSueldoComisionJSON,
                    informacionDineroEntregado: informacionDineroEntregadoJSON,
                    informacionGastos: informacionGastosJSON, 
                    informacionCuentasOperador: informacionCuentasOperadorJSON
                })
            });

            const data = await response.json();
    
            if (data.success) {
                toast.success("LIQUIDACION #"+nLiquidacion+ " REGISTRADA CORRECTAMENTE", {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
                setLoading(false);
                handleRefetch();
                handleClose();
            } else {
                toast.error(data.error, {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
                setLoading(false);
                // console.log("ERROR AL REGISTRAR LA NUEVA LIQUIDACION");
            }
        } catch (error) {
            console.error('Error al conectar con el servidor:', error);
            setLoading(false);
        }

    };

    async function obtenerSiguienteLiquidacion() {
        try {
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_select/SiguienteLiquidacion.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();
    
            if (data.success) {
                setUltimaLiquidacion(data.liquidacion);
            } else {
                // console.log("ERROR AL SELECCIONAR LA SIGUIENTE LIQUIDACION");
            }
        } catch (error) {
            console.error('Error al conectar con el servidor:', error);
        }
    }

    async function obtenerOperadoresRegistrados() {
        try {
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_select/OperadoresRegistrados.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();
    
            if (data.success) {
                setOperadores(data.operadoresregistrados);
            } else {
                // console.log("ERROR AL SELECCIONAR LOS OPERADORES REGISTRADOS");
            }
        } catch (error) {
            console.error('Error al conectar con el servidor:', error);
        }
    }

    async function obtenerTractosRegistrados() {
        try {
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_select/TractosRegistrados.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();
    
            if (data.success) {
                setTractos(data.tractosregistrados);
            } else {
                // console.log("ERROR AL SELECCIONAR LOS TRACTOS REGISTRADOS");
            }
        } catch (error) {
            console.error('Error al conectar con el servidor:', error);
        }
    }

    async function obtenerCajasRegistrados() {
        try {
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_select/CajasRegistrados.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();
    
            if (data.success) {
                setCajas(data.cajasregistrados);
            } else {
                // console.log("ERROR AL SELECCIONAR LAS CAJAS REGISTRADAS");
            }
        } catch (error) {
            console.error('Error al conectar con el servidor:', error);
        }
    }
    
    const handleCloseFunction = () => {
        setCollapseState(prevState => ({
            ...prevState,
            sueldoComision: true,
            dineroEntregado: false,
            gastosOperador: false,
            cuentasOperador: false
        }));
        handleClose();
    }

    const [dragging, setDragging] = useState(false);
    
    const handleDragStart = (e, index) => {
        setDragging(true);
        e.dataTransfer.setData('text/plain', index);
    };
    
    const handleDragOver = (e) => {
        e.preventDefault();
    };
    
    const handleDropSC = (e, dropIndex) => {
        e.preventDefault();
        setDragging(false);
        const dragIndex = e.dataTransfer.getData('text/plain');
        
        // Mueve el campo arrastrado a la nueva posición
        const updatedFields = [...extraFieldsSC];
        const [movedField] = updatedFields.splice(dragIndex, 1);
        updatedFields.splice(dropIndex, 0, movedField);
        setExtraFieldsSC(updatedFields);
    };

    const handleDropDineroEntregado = (e, dropIndex) => {
        e.preventDefault();
        setDragging(false);
        const dragIndex = e.dataTransfer.getData('text/plain');
        
        // Mueve el campo arrastrado a la nueva posición
        const updatedFields = [...extraFieldsDineroEntregado];
        const [movedField] = updatedFields.splice(dragIndex, 1);
        updatedFields.splice(dropIndex, 0, movedField);
        setExtraFieldsDineroEntregado(updatedFields);
    };

    const handleDropGastos = (e, dropIndex) => {
        e.preventDefault();
        setDragging(false);
        const dragIndex = e.dataTransfer.getData('text/plain');
        
        // Mueve el campo arrastrado a la nueva posición
        const updatedFields = [...extraFieldsGastos];
        const [movedField] = updatedFields.splice(dragIndex, 1);
        updatedFields.splice(dropIndex, 0, movedField);
        setExtraFieldsGastos(updatedFields);
    };

    const handleDropCuentasOperador = (e, dropIndex) => {
        e.preventDefault();
        setDragging(false);
        const dragIndex = e.dataTransfer.getData('text/plain');
        
        // Mueve el campo arrastrado a la nueva posición
        const updatedFields = [...extraFieldsCuentasConOperador];
        const [movedField] = updatedFields.splice(dragIndex, 1);
        updatedFields.splice(dropIndex, 0, movedField);
        setExtraFieldsCuentasConOperador(updatedFields);
    };
 
    return(
        <div className='modal-content'>
            <Modal show={show} fullscreen = {true} onHide={handleCloseFunction} backdrop="static" keyboard={false}>
                <Form onSubmit={enviarRegistroLiquidacion}>
                    <Modal.Header closeButton>
                        <Modal.Title>NUEVA LIQUIDACION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: 'calc(100vh - 150px)'}}>
                        <Row>
                            <Col md={2}>
                                <Form.Label htmlFor="idLiquidacion" className="form-label">N. LIQUIDACION:</Form.Label>
                                <Form.Control type='number' className='form-control' id='idLiquidacion' defaultValue={ultimaliquidacion}></Form.Control>
                            </Col>
                            <Col md={4}>
                                <Form.Label htmlFor="nombreOperador" className="form-label">NOMBRE DEL OPERADOR:</Form.Label>
                                <Form.Select  type='text' className='form-control' id='nombreOperador' defaultValue={""} >
                                    <option disabled value="">NOMBRE DEL OPERADOR</option>
                                    {operadores.map((operador, index) => (
                                        <option key={index} value={operador.nombre}>{operador.nombre}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col md={3}>
                                <Form.Label htmlFor="tractoEconomico" className="form-label">ECONOMICO TRACTO:</Form.Label>
                                <Form.Select  type='text' className='form-control' id='tractoEconomico' defaultValue={""} >
                                    <option disabled value="">ECONOMICO TRACTO</option>
                                    {tractos.map((tracto, index) => (
                                        <option key={index} value={tracto.economico}>{tracto.economico}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col md={3}>
                                <Form.Label htmlFor="cajaEconomico" className="form-label">ECONOMICO CAJA:</Form.Label>
                                <Form.Select  type='text' className='form-control' id='cajaEconomico' defaultValue={""} >
                                    <option disabled value="">ECONOMICO CAJA</option>
                                    {cajas.map((caja, index) => (
                                        <option key={index} value={caja.economico}>{caja.economico}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <hr style={{border: "1px solid #ffff"}} />

                        <div className="row menuColaps">
                            <div className="col-md-12">
                                <button className="btn btn-primary" style={{border: collapseState.sueldoComision ? 'var(--main-color) solid 1px !important' : 'none' }}  type="button" data-bs-toggle="collapse" data-bs-target="#sueldo-comision" aria-expanded="false" aria-controls="collapseExample" onClick={() => toggleCollapse('sueldoComision')}>
                                    SUELDO-COMISION
                                </button>
                                <button className="btn btn-primary" style={{border: collapseState.dineroEntregado ? 'var(--main-color) solid 1px !important' : 'none' }} type="button" data-bs-toggle="collapse" data-bs-target="#dineroEntregado" aria-expanded="false" aria-controls="collapseExample" onClick={() => toggleCollapse('dineroEntregado')}>
                                    DINERO ENTREGADO
                                </button>
                                <button className="btn btn-primary" style={{border: collapseState.gastosOperador ? 'var(--main-color) solid 1px !important' : 'none' }} type="button" data-bs-toggle="collapse" data-bs-target="#gastosOperador" aria-expanded="false" aria-controls="collapseExample" onClick={() => toggleCollapse('gastosOperador')}>
                                    GASTOS
                                </button>
                                <button className="btn btn-primary" style={{border: collapseState.cuentasOperador ? 'var(--main-color) solid 1px !important' : 'none' }} type="button" data-bs-toggle="collapse" data-bs-target="#cuentasOperador" aria-expanded="false" aria-controls="collapseExample" onClick={() => toggleCollapse('cuentasOperador')}>
                                    CUENTAS CON OPERADOR
                                </button>
                            </div>
                        </div>

                        {/* REGISTRAR SUELDO COMISION */}
                        <div className="collapse show" id="sueldo-comision">
                            <div id="conceptList">
                                <Card style={{marginTop: "10px"}}>
                                    <Card.Header>
                                        <h5 className='text-center'>SUELDO - COMISION</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <div id="campoSC">
                                            <Row className='text-center'>
                                                <Col md = {2}>
                                                    <Form.Label htmlFor="fechaSueldoComision" className="form-label">FECHA:</Form.Label>
                                                    <Form.Control type='date' className='form-control' id='fechaSueldoComision' defaultValue={""}></Form.Control>
                                                </Col>
                                                <Col md = {2}>
                                                    <Form.Label htmlFor="inicioSueldoComision" className="form-label">INICIO:</Form.Label>
                                                    <Form.Control type='text' className='form-control' id='inicioSueldoComision' defaultValue={""}></Form.Control>
                                                </Col>
                                                <Col md = {2}>
                                                    <Form.Label htmlFor="destinoSueldoComision" className="form-label">DESTINO:</Form.Label>
                                                    <Form.Control type='text' className='form-control' id='destinoSueldoComision' defaultValue={""}></Form.Control>
                                                </Col>
                                                <Col md = {2}>
                                                    <Form.Label htmlFor="cargaSueldoComision" className="form-label">CARGA:</Form.Label>
                                                    <Form.Control type='text' className='form-control' id='cargaSueldoComision' defaultValue={""}></Form.Control>
                                                </Col>
                                                <Col md = {2}>
                                                    <Form.Label htmlFor="comisionSueldoComision" className="form-label">$ COMISION:</Form.Label>
                                                    <Form.Control type='number' className='form-control' id='comisionSueldoComision' defaultValue={""} placeholder='$' onClick={() => updateSubTotalSueldoComision()} onInput={() => updateSubTotalSueldoComision()}></Form.Control>
                                                </Col>
                                                <Col md = {2}>
                                                    <Form.Label htmlFor="barcoSueldoComision" className="form-label">$ BARCO:</Form.Label>
                                                    <Form.Control type='number' className='form-control' id='barcoSueldoComision' defaultValue={""} placeholder='$' onClick={() => updateSubTotalSueldoComision()} onInput={() => updateSubTotalSueldoComision()}></Form.Control>
                                                </Col>
                                                <Col md = {2}>
                                                    <Form.Label htmlFor="transferSueldoComision" className="form-label">$ TRANSFER:</Form.Label>
                                                    <Form.Control type='number' className='form-control' id='transferSueldoComision' defaultValue={""} placeholder='$' onClick={() => updateSubTotalSueldoComision()} onInput={() => updateSubTotalSueldoComision()}></Form.Control>
                                                </Col>
                                                <Col md = {2}>
                                                    <Form.Label htmlFor="amountSueldoComision" className="form-label">$ FLETE:</Form.Label>
                                                    <Form.Control type='number' className='form-control' id='amountSueldoComision' defaultValue={""} placeholder='$' onClick={() => updateSubTotalSueldoComision()} onInput={() => updateSubTotalSueldoComision()}></Form.Control>
                                                </Col>
                                            </Row>
                                            <hr style={{border: "1px solid #ffff"}} />
                                        </div>
                                        <div id="extraFieldsSueldoComision">
                                            {extraFieldsSC.map((field, index) => (
                                                <div id = {"campoSC_"+index} key={index} draggable="true" onDragStart={(e) => handleDragStart(e, index)} onDragOver={handleDragOver} onDrop={(e) => handleDropSC(e, index)} style={{ border: dragging ? '1px dashed #000' : 'none' }}>
                                                    <Row className='text-center'>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor={`fechaSueldoComision${index}`} className="form-label">FECHA:</Form.Label>
                                                            <Form.Control type='date' className='form-control' id={`fechaSueldoComision${index}`} value={field.fecha} onChange={(e) => handleFieldChangeSueldoComision(index, 'fecha', e.target.value)}></Form.Control>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor={`inicioSueldoComision${index}`} className="form-label">INICIO:</Form.Label>
                                                            <Form.Control type='text' className='form-control' id={`inicioSueldoComision${index}`} value={field.inicio} onChange={(e) => handleFieldChangeSueldoComision(index, 'inicio', e.target.value)}></Form.Control>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor={`destinoSueldoComision${index}`} className="form-label">DESTINO:</Form.Label>
                                                            <Form.Control type='text' className='form-control' id={`destinoSueldoComision${index}`} value={field.destino} onChange={(e) => handleFieldChangeSueldoComision(index, 'destino', e.target.value)}></Form.Control>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor={`cargaSueldoComision${index}`} className="form-label">CARGA:</Form.Label>
                                                            <Form.Control type='text' className='form-control' id={`cargaSueldoComision${index}`} value={field.carga} onChange={(e) => handleFieldChangeSueldoComision(index, 'carga', e.target.value)}></Form.Control>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor={`comisionSueldoComision${index}`} className="form-label">$ COMISION:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`comisionSueldoComision${index}`} value={field.comision} onChange={(e) => handleFieldChangeSueldoComision(index, 'comision', e.target.value)} placeholder='$' onClick={() => updateSubTotalSueldoComision()} onInput={() => updateSubTotalSueldoComision()}></Form.Control>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor={`barcoSueldoComision${index}`} className="form-label">$ BARCO:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`barcoSueldoComision${index}`} value={field.barco} onChange={(e) => handleFieldChangeSueldoComision(index, 'barco', e.target.value)} placeholder='$' onClick={() => updateSubTotalSueldoComision()} onInput={() => updateSubTotalSueldoComision()}></Form.Control>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor={`transferSueldoComision${index}`} className="form-label">$ TRANSFER:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`transferSueldoComision${index}`} value={field.transfer} onChange={(e) => handleFieldChangeSueldoComision(index, 'transfer', e.target.value)} placeholder='$' onClick={() => updateSubTotalSueldoComision()} onInput={() => updateSubTotalSueldoComision()}></Form.Control>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor={`amountSueldoComision${index}`} className="form-label">$ FLETE:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`amountSueldoComision${index}`} value={field.flete} onChange={(e) => handleFieldChangeSueldoComision(index, 'flete', e.target.value)} placeholder='$' onClick={() => updateSubTotalSueldoComision()} onInput={() => updateSubTotalSueldoComision()}></Form.Control>
                                                        </Col>
                                                    </Row>
                                                    <button type="button" className="btn btn-sm btn-danger mt-1" onClick={() => removeExtraFieldSueldoComision(index)}>ELIMINAR</button>
                                                    <hr style={{border: "1px solid #ffff"}} />
                                                </div>
                                            ))}
                                        </div>
                                        <Button variant='info' style = {{marginTop: '10px', color: '#fff'}} onClick={() => addExtraFieldSueldoComision()}>CAMPO ADICIONAL</Button>

                                        <Col md = {2} className='text-center' style={{marginLeft: "84%"}}>
                                            <Form.Label htmlFor="subTotalAmountSueldoComision" className="form-label">$ SUBTOTAL:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='subTotalAmountSueldoComision' placeholder='$' readOnly></Form.Control>
                                        </Col>
                                        <Col md = {2} className='text-center' style={{marginLeft: "84%"}}>
                                            <Form.Label htmlFor="porcentajeAmountSueldoComision" className="form-label">% FLETE:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='porcentajeAmountSueldoComision'  placeholder='%' defaultValue={"16"} onChange={() => porcentajeSueldoComision()} onInput={() => porcentajeSueldoComision()}></Form.Control>
                                        </Col>
                                        <Col md = {2} className='text-center' style={{marginLeft: "84%"}}>
                                            <Form.Label htmlFor="totalAmountSueldoComision" className="form-label">$ TOTAL:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='totalAmountSueldoComision' placeholder='$' readOnly></Form.Control>
                                        </Col>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                        {/* DINERO ENTREGADO */}
                        <div className="collapse" id="dineroEntregado">
                            <div id="conceptList">
                                <Card style={{marginTop: "10px"}}>
                                    <Card.Header>
                                        <h5 className='text-center'>DINERO ENTREGADO AL OPERADOR</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className='text-center'>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="fechaDineroEntregado" className="form-label">FECHA:</Form.Label>
                                                <Form.Control type='date' className='form-control' id='fechaDineroEntregado' defaultValue={""}></Form.Control>
                                            </Col>
                                            <Col md = {8}>
                                                <Form.Label htmlFor="descriptionDineroEntregado" className="form-label">DESCRIPCION:</Form.Label>
                                                <Form.Control type='text' className='form-control' id='descriptionDineroEntregado' defaultValue={""}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="amountDineroEntregado" className="form-label">$ CANTIDAD:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='amountDineroEntregado' defaultValue={""} placeholder='$' onClick={() => updateTotalDineroEntregado()} onInput={() => updateTotalDineroEntregado()}></Form.Control>
                                            </Col>
                                        </Row>
                                        <hr style={{border: "1px solid #ffff"}} />
                                        <div id="extraFieldsDineroEntregado">
                                            {extraFieldsDineroEntregado.map((field, index) => (
                                                <div id = {"campoDE_"+index} key={index} draggable="true" onDragStart={(e) => handleDragStart(e, index)} onDragOver={handleDragOver} onDrop={(e) => handleDropDineroEntregado(e, index)} style={{ border: dragging ? '1px dashed #000' : 'none' }}>
                                                    <Row className='text-center'>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`fechaDineroEntregado${index}`} className="form-label">FECHA:</Form.Label>
                                                            <Form.Control type='date' className='form-control' id={`fechaDineroEntregado${index}`} value={field.fecha} onChange={(e) => handleFieldChangeDineroEntregado(index, 'fecha', e.target.value)}></Form.Control>
                                                        </Col>
                                                        <Col md = {8}>
                                                            <Form.Label htmlFor={`descriptionDineroEntregado${index}`} className="form-label">DESCRIPCION:</Form.Label>
                                                            <Form.Control type='text' className='form-control' id={`descriptionDineroEntregado${index}`} value={field.descripcion} onChange={(e) => handleFieldChangeDineroEntregado(index, 'descripcion', e.target.value)}></Form.Control>
                                                        </Col>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`amountDineroEntregado${index}`} className="form-label">$ CANTIDAD:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`amountDineroEntregado${index}`} value={field.cantidad} onChange={(e) => handleFieldChangeDineroEntregado(index, 'cantidad', e.target.value)} placeholder='$' onClick={() => updateTotalDineroEntregado()} onInput={() => updateTotalDineroEntregado()}></Form.Control>
                                                        </Col>
                                                    </Row>
                                                    <button type="button" className="btn btn-sm btn-danger mt-1" onClick={() => removeExtraFieldDineroEntregado(index)}>ELIMINAR</button>
                                                    <hr style={{border: "1px solid #ffff"}} />
                                                </div>
                                            ))}
                                        </div>
                                        <Button variant='info' style = {{marginTop: '10px', color: '#fff'}} onClick={() => addExtraFieldDineroEntregado()}>CAMPO ADICIONAL</Button>

                                        <Col md = {2} className='text-center' style={{marginLeft: '84%'}}>
                                            <Form.Label htmlFor="totalAmountDineroEntregado" className="form-label">$ TOTAL:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='totalAmountDineroEntregado' defaultValue={""} readOnly placeholder='$'></Form.Control>
                                        </Col>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                        {/* GASTOS */}
                        <div className="collapse" id="gastosOperador">
                            <div id="conceptList">
                                <Card style={{marginTop: "10px"}}>
                                    <Card.Header>
                                        <h5 className='text-center'>GASTOS</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className='text-center'>
                                            <Col md = {10}>
                                                <Form.Label htmlFor="description" className="form-label">DESCRIPCION:</Form.Label>
                                                <Form.Control type='text' className='form-control' id='description' defaultValue={""}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="amount" className="form-label">$ CANTIDAD:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='amount' defaultValue={""} placeholder='$' onClick={() => updateTotalGastos()} onInput={() => updateTotalGastos()}></Form.Control>
                                            </Col>
                                        </Row>
                                        <hr style={{border: "1px solid #ffff"}} />
                                        <div id="extraFieldsGastos">
                                            {extraFieldsGastos.map((field, index) => (
                                                <div id = {"campoG_"+index} key={index} draggable="true" onDragStart={(e) => handleDragStart(e, index)} onDragOver={handleDragOver} onDrop={(e) => handleDropGastos(e, index)} style={{ border: dragging ? '1px dashed #000' : 'none' }}>
                                                    <Row className='text-center'>
                                                        <Col md = {10}>
                                                            <Form.Label htmlFor = {`description${index}`} className="form-label">DESCRIPCION:</Form.Label>
                                                            <Form.Control type='text' className='form-control' id={`description${index}`} value={field.descripcion} onChange={(e) => handleFieldChangeGastos(index, "descripcion", e.target.value)}></Form.Control>
                                                        </Col>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`amount${index}`} className="form-label">$ CANTIDAD:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`amount${index}`} value={field.cantidad} onChange={(e) => handleFieldChangeGastos(index, "cantidad", e.target.value)} placeholder='$' onClick={() => updateTotalGastos()} onInput={() => updateTotalGastos()}></Form.Control>
                                                        </Col>
                                                    </Row>
                                                    <button type="button" className="btn btn-sm btn-danger mt-1" onClick={() => removeExtraFieldGastos(index)}>ELIMINAR</button>
                                                    <hr style={{border: "1px solid #ffff"}} />
                                                </div>
                                            ))}
                                        </div>
                                        <Button variant='info' style = {{marginTop: '10px', color: '#fff'}} onClick={() => addExtraFieldGastos()}>CAMPO ADICIONAL</Button>

                                        <Col md = {2} className='text-center' style={{marginLeft: '84%'}}>
                                            <Form.Label htmlFor="totalAmount" className="form-label">$ TOTAL:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='totalAmountGastos' defaultValue={""} readOnly placeholder='$'></Form.Control>
                                        </Col>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                        {/* CUENTAS CON OPERADOR */}
                        <div className="collapse" id="cuentasOperador">
                            <div id="conceptList">
                            <Card style={{marginTop: "10px"}}>
                                <Card.Header>
                                    <h5 className='text-center'>CUENTAS CON OPERADOR</h5>
                                </Card.Header>
                                <Card.Body>
                                    <Row className='text-center'>
                                        <Col md = {10}>
                                            <Form.Label htmlFor="descriptionCuentasOperador1" className="form-label">DESCRIPCION:</Form.Label>
                                            <Form.Control type='text' className='form-control' id='descriptionCuentasOperador1' value={"DINERO ENTREGADO"} readOnly></Form.Control>
                                        </Col>
                                        <Col md = {2}>
                                            <Form.Label htmlFor="amountCuentasOperador1" className="form-label">$ CANTIDAD:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='amountCuentasOperador1' defaultValue={""} readOnly placeholder='$' onChange={() => updateTotalCuentasOperador()} onInput={() => updateTotalCuentasOperador()}></Form.Control>
                                        </Col>
                                    </Row>
                                    <hr style={{border: "1px solid #ffff"}} />
                                    <Row className='text-center'>
                                        <Col md = {10}>
                                            <Form.Label htmlFor="descriptionCuentasOperador2" className="form-label">DESCRIPCION:</Form.Label>
                                            <Form.Control type='text' className='form-control' id='descriptionCuentasOperador2' value={"GASTOS QUE HIZO"} readOnly></Form.Control>
                                        </Col>
                                        <Col md = {2}>
                                            <Form.Label htmlFor="amountCuentasOperador2" className="form-label">$ CANTIDAD:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='amountCuentasOperador2' defaultValue={""} readOnly placeholder='$' onChange={() => updateTotalCuentasOperador()} onInput={() => updateTotalCuentasOperador()}></Form.Control>
                                        </Col>
                                    </Row>
                                    <hr style={{border: "1px solid #ffff"}} />
                                    <Row className='text-center'>
                                        <Col md = {10}>
                                            <Form.Label htmlFor="descriptionCuentasOperador3" className="form-label">DESCRIPCION:</Form.Label>
                                            <Form.Control type='text' className='form-control' id='descriptionCuentasOperador3' value={"LE SOBRO Y SE ABONO A SU SUELDO"} readOnly></Form.Control>
                                        </Col>
                                        <Col md = {2}>
                                            <Form.Label htmlFor="amountCuentasOperador3" className="form-label">$ CANTIDAD:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='amountCuentasOperador3' defaultValue={""} readOnly placeholder='$' onChange={() => updateTotalCuentasOperador()} onInput={() => updateTotalCuentasOperador()}></Form.Control>
                                        </Col>
                                    </Row>
                                    <hr style={{border: "1px solid #ffff"}} />
                                    <Row className='text-center'>
                                        <Col md = {10}>
                                            <Form.Label htmlFor="descriptionCuentasOperador4" className="form-label">DESCRIPCION:</Form.Label>
                                            <Form.Control type='text' className='form-control' id='descriptionCuentasOperador4' value={"SUELDO - COMISION"} readOnly></Form.Control>
                                        </Col>
                                        <Col md = {2}>
                                            <Form.Label htmlFor="amountCuentasOperador4" className="form-label">$ CANTIDAD:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='amountCuentasOperador4' defaultValue={""} readOnly placeholder='$' onChange={() => updateTotalCuentasOperador()} onInput={() => updateTotalCuentasOperador()}></Form.Control>
                                        </Col>
                                    </Row>
                                    <hr style={{border: "1px solid #ffff"}} />
                                    <div id="extraFieldsCuentasOperador">
                                        {extraFieldsCuentasConOperador.map((field, index) => (
                                            <div id = {"campoCCP_"+index} key={index} draggable="true" onDragStart={(e) => handleDragStart(e, index)} onDragOver={handleDragOver} onDrop={(e) => handleDropCuentasOperador(e, index)} style={{ border: dragging ? '1px dashed #000' : 'none' }}>
                                                <Row className='text-center'>
                                                    <Col md = {10}>
                                                        <Form.Label htmlFor={`descriptionCuentasOperador${index}`} className="form-label">DESCRIPCION:</Form.Label>
                                                        <Form.Control type='text' className='form-control' id={`descriptionCuentasOperador${index}`} value={field.descripcion} onChange={(e) => handleFieldChangeCuentasOperadorEditar(index, "descripcion", e.target.value)}></Form.Control>
                                                    </Col>
                                                    <Col md = {2}>
                                                        <Form.Label htmlFor={`amountCuentasOperador${index}`} className="form-label">$ CANTIDAD:</Form.Label>
                                                        <Form.Control type='number' className='form-control' id={`amountCuentasOperador${index}`} value={field.cantidad} placeholder='$' onChange={(e) => handleFieldChangeCuentasOperadorEditar(index, "cantidad", e.target.value)} onClick={() => updateTotalCuentasOperador()} onInput={() => updateTotalCuentasOperador()}></Form.Control>
                                                    </Col>
                                                </Row>
                                                <button type="button" className="btn btn-sm btn-danger mt-1" onClick={() => removeExtraFieldCuentasOperador(index)}>ELIMINAR</button>
                                                <hr style={{border: "1px solid #ffff"}} />
                                            </div>
                                        ))}
                                    </div>
                                    <Button variant='info' style = {{marginTop: '10px', color: '#fff'}} onClick={() => addExtraFieldCuentasOperador()}>CAMPO ADICIONAL</Button>

                                    <Col md = {8} style={{marginTop: '10px'}}>
                                        <Form.Label htmlFor="observacionesCuentasOperador" className="form-label">OBSERVACIONES:</Form.Label>
                                        <textarea className="form-control" id="observacionesCuentasOperador" style={{color: '#fff'}} ></textarea>
                                    </Col>

                                    <Col md = {2} className='text-center' style={{marginLeft: '84%'}}>
                                        <Form.Label htmlFor="totalAmountCuentasOperador" className="form-label">TOTAL A PAGAR:</Form.Label>
                                        <Form.Control type='number' className='form-control' id='totalAmountCuentasOperador' defaultValue={""} readOnly placeholder='$'></Form.Control>
                                    </Col>

                                </Card.Body>
                            </Card>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleCloseFunction}>CANCELAR</Button>
                        {
                            loading ?
                            <Button disabled className='btnFormularios' variant="primary" type="submit"><span className="spinner-border spinner-border-sm" aria-hidden="true"></span> REGISTRANDO LIQUIDACION</Button>
                            :
                            <Button className='btnFormularios' variant="primary" type="submit">REGISTRAR LIQUIDACION</Button>
                        }
                        
                    </Modal.Footer>
                </Form>
            </Modal>
            <Toaster position="top-right" reverseOrder={false}/>
        </div>
    );
}

export default RegistrarLiquidacion