//SUELDO-COMISION
export function updateSubTotalSueldoComision() {
    var subTotalSueldoComision = 0;

    //COMISION
    var comisionSueldoComision = 0;
    var comisionDefaultAmount = document.getElementById("comisionSueldoComision").value;
    comisionSueldoComision += parseFloat(comisionDefaultAmount) || 0;

    // //BARCO
    var barcoSueldoComision = 0;
    var barcoDefaultAmount = document.getElementById("barcoSueldoComision").value;
    barcoSueldoComision += parseFloat(barcoDefaultAmount) || 0;

    // //TRANSFER
    var transferSueldoComision = 0;
    var transferDefaultAmount = document.getElementById("transferSueldoComision").value;
    transferSueldoComision += parseFloat(transferDefaultAmount) || 0;

    // //Flete
    var fleteSueldoComision = 0;
    var fleteDefaultAmount = document.getElementById("amountSueldoComision").value;
    fleteSueldoComision += parseFloat(fleteDefaultAmount) || 0;

    var fechaSueldoComision = document.querySelectorAll("#extraFieldsSueldoComision input[type='date']");
    var amountsSueldoComision = document.querySelectorAll("#extraFieldsSueldoComision input[type='number']");
    for (let i = 0; i < fechaSueldoComision.length; i++) {
        var posComision = i * 4;
        var posBarco = i * 4 + 1;
        var posTransfer = i * 4 + 2;
        var posFlete = i * 4 + 3;
        
        comisionSueldoComision += parseFloat(amountsSueldoComision[posComision].value) || 0;
        barcoSueldoComision += parseFloat(amountsSueldoComision[posBarco].value) || 0;
        transferSueldoComision += parseFloat(amountsSueldoComision[posTransfer].value) || 0;
        fleteSueldoComision += parseFloat(amountsSueldoComision[posFlete].value) || 0;

    }
    subTotalSueldoComision = fleteSueldoComision - comisionSueldoComision - transferSueldoComision - barcoSueldoComision;
    document.getElementById("subTotalAmountSueldoComision").value = subTotalSueldoComision.toFixed(3);
    porcentajeSueldoComision();
}

export function porcentajeSueldoComision(){
    var subTotal = document.getElementById("subTotalAmountSueldoComision").value;
    var porcentaje = document.getElementById("porcentajeAmountSueldoComision").value;

    var resultado = (subTotal * (porcentaje / 100)).toFixed(3);
    document.getElementById("totalAmountSueldoComision").value = resultado;

    //CUENTAS OPERADOR
    document.getElementById("amountCuentasOperador4").value = resultado;

    var sobroAbonoASuSueldo = document.getElementById("amountCuentasOperador3").value;
    var sueldoComisionTotal = document.getElementById("amountCuentasOperador4").value;
    var totalExtrasCuentasOperador = 0;
    var cantidadesExtrasCuentasOperador = document.querySelectorAll("#extraFieldsCuentasOperador input[type='number']");
    cantidadesExtrasCuentasOperador.forEach(function(amountInputCuentasOperador) {
        totalExtrasCuentasOperador += parseFloat(amountInputCuentasOperador.value) || 0;
    });

    document.getElementById("totalAmountCuentasOperador").value = (sueldoComisionTotal - sobroAbonoASuSueldo - totalExtrasCuentasOperador).toFixed(3);

}

var camposAdicionalesSueldoComision = [];
export function getInformacionSueldoComision(){
    var fechaSueldoComision = document.getElementById("fechaSueldoComision").value;
    var inicioSueldoComision = document.getElementById("inicioSueldoComision").value;
    var destinoSueldoComision = document.getElementById("destinoSueldoComision").value;
    var cargaSueldoComision = document.getElementById("cargaSueldoComision").value;
    var comisionSueldoComision = document.getElementById("comisionSueldoComision").value;
    var barcoSueldoComision = document.getElementById("barcoSueldoComision").value;
    var transferSueldoComision = document.getElementById("transferSueldoComision").value;
    var amountSueldoComision = document.getElementById("amountSueldoComision").value;
    var totalSueldoComision = document.getElementById("totalAmountSueldoComision").value;

    var fechaSueldoComisionExtras = document.querySelectorAll("#extraFieldsSueldoComision input[type='date']");
    var camposExtrasSueldoComision = document.querySelectorAll("#extraFieldsSueldoComision input[type='text']");
    var camposExtrasCantidadComision = document.querySelectorAll("#extraFieldsSueldoComision input[type='number']");

    var campoExtraFechaSueldoComision = "";
    var campoComisionSueldoComision = 0;
    var campoBarcoSueldoComision = 0;
    var campoTransferSueldoComision = 0;
    var campoCantidadSueldoComision = 0;
    var campoExtraInicioSueldoComision = "";
    var campoExtraDestinoSueldoComision = "";
    var campoCargaSueldoComision = "";

    camposAdicionalesSueldoComision.length = 0;
    for (let i = 0; i < fechaSueldoComisionExtras.length; i++) {
        campoExtraFechaSueldoComision = fechaSueldoComisionExtras[i].value;

        var posComision = i * 4;
        var posBarco = i * 4 + 1;
        var posTransfer = i * 4 + 2;
        var posFlete = i * 4 + 3;

        campoComisionSueldoComision = camposExtrasCantidadComision[posComision].value;
        campoBarcoSueldoComision = camposExtrasCantidadComision[posBarco].value;
        campoTransferSueldoComision = camposExtrasCantidadComision[posTransfer].value;
        campoCantidadSueldoComision = camposExtrasCantidadComision[posFlete].value;
    
        let indexInicio = i * 3;
        let indexDestino = i * 3 + 1;
        let indexCarga = i * 3 + 2;
    
        campoExtraInicioSueldoComision = camposExtrasSueldoComision[indexInicio].value;
        campoExtraDestinoSueldoComision = camposExtrasSueldoComision[indexDestino].value;
        campoCargaSueldoComision = camposExtrasSueldoComision[indexCarga].value;
    
        camposAdicionalesSueldoComision.push({ 
            Fecha: campoExtraFechaSueldoComision, 
            Inicio: campoExtraInicioSueldoComision, 
            Destino: campoExtraDestinoSueldoComision, 
            Carga: campoCargaSueldoComision,
            Comision: campoComisionSueldoComision,
            Barco: campoBarcoSueldoComision,
            Transfer: campoTransferSueldoComision,
            Cantidad: campoCantidadSueldoComision
        });
    }

    // if (fechaSueldoComision === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA FECHA N.1 DE SUELDO-COMISION"
    //     };
    // }

    // if (inicioSueldoComision === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE EL INICIO N.1 DE SUELDO-COMISION",
    //     };
    // }

    // if (destinoSueldoComision === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE EL DESTINO N.1 DE SUELDO-COMISION",
    //     };
    // }

    // if (cargaSueldoComision === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA CARGA N.1 DE SUELDO-COMISION",
    //     };
    // }

    // if (amountSueldoComision === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA CANTIDAD DE FLETE N.1 de SUELDO-COMISION",
    //     };
    // }

    // for (let i = 0; i < fechaSueldoComisionExtras.length; i++) {    
    //     let indexInicio = i * 3;
    //     let indexDestino = i * 3 + 1;
    //     let indexCarga = i * 3 + 2;
    //     let posFlete = i * 4 + 3;

    //     if (fechaSueldoComisionExtras[i].value === ""){
    //         return {
    //             success: false,
    //             error: "INGRESE LA FECHA N."+(i+2)+" DE SUELDO-COMISION"
    //         };
    //     }

    //     if (camposExtrasSueldoComision[indexInicio].value === ""){
    //         return {
    //             success: false,
    //             error: "INGRESE EL INICIO N."+(indexInicio+2)+" DE SUELDO-COMISION"
    //         };
    //     }

    //     if (camposExtrasSueldoComision[indexDestino].value === ""){
    //         return {
    //             success: false,
    //             error: "INGRESE EL DESTINO N."+(indexDestino+1)+" DE SUELDO-COMISION"
    //         };
    //     }

    //     if (camposExtrasSueldoComision[indexCarga].value === ""){
    //         return {
    //             success: false,
    //             error: "INGRESE LA CARGA N."+(indexCarga)+" DE SUELDO-COMISION"
    //         };
    //     }

    //     if (camposExtrasCantidadComision[posFlete].value === ""){
    //         return {
    //             success: false,
    //             error: "INGRESE LA CANTIDAD N."+(i+2)+" DE SUELDO-COMISION"
    //         };
    //     }
    
    // }

    var informacionSueldoComision = {
        fechaSueldoComision: fechaSueldoComision,
        inicioSueldoComision: inicioSueldoComision,
        destinoSueldoComision: destinoSueldoComision,
        cargaSueldoComision: cargaSueldoComision,
        comisionSueldoComision: comisionSueldoComision,
        barcoSueldoComision: barcoSueldoComision,
        transferSueldoComision: transferSueldoComision,
        amountSueldoComision: amountSueldoComision,
        camposAdicionalesSueldoComision: camposAdicionalesSueldoComision,
        totalSueldoComision: totalSueldoComision
    };

    return informacionSueldoComision;
}