import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPen, faGear, faPenToSquare, faTrash, faList, faDollarSign, faFile, faPrint} from '@fortawesome/free-solid-svg-icons';
import Sliderbar from '../../utils/Sliderbar';
import RegistrarLiquidacion from '../../utils/RegistrarLiquidacion';
import EditarLiquidacion from '../../utils/EditarLiquidacion';

import EliminarModal from '../../utils/EliminarModal';
import DetallesModal from '../../utils/DetallesModal';
import PagarModal from '../../utils/PagarModal';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import toast, { Toaster } from 'react-hot-toast';
import { useQuery } from "react-query";

function Liquidacion() {
    const [showModalRegistrarLiquidacion, setShowModalRegistrarLiquidacion] = useState(false);
    const [showModalEditarLiquidacion, setShowModalEditarLiquidacion] = useState(false);
    const [showModalEliminar, setShowModalEliminar] = useState(false);
    const [showModalDetalles, setShowModalDetalles] = useState(false);
    const [showModalPagar, setShowModalPagar] = useState(false);
    const [rowData, setRowDataLiquidacion] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);

    const { data: liquidacionesData, isLoading, refetch } = useQuery("liquidaciones", mostrarLiquidaciones);

    useEffect(() => {
        document.title = "LIQUIDACION - OPERADORES";
        refetch();
    }, [refetch]);

    const userLogeado = localStorage.getItem("login");
    var userLogeadoPermisos = JSON.parse(userLogeado);
    var permisos = JSON.parse(userLogeadoPermisos[0].permisos);

    const handleShowModalEliminar = (rowData) => {
        setRowDataLiquidacion(rowData);
        setShowModalEliminar(true);
    }

    const handleCloseModalEliminar = () => {
        setShowModalEliminar(false);
    }

    const handleShowModalDetalles = (rowData) => {
        setRowDataLiquidacion(rowData);
        setShowModalDetalles(true);
    }

    const handleCloseModalDetalles = () => {
        setShowModalDetalles(false);
    }

    const handleShowModalPagar = (rowData) => {
        setRowDataLiquidacion(rowData);
        setShowModalPagar(true);
    }

    const handleCloseModalPagar = () => {
        setShowModalPagar(false);
    }

    const handleShowModalRegistrarLiquidacion = () => {
        setShowModalRegistrarLiquidacion(true);
    }

    const handleCloseModalRegistrarLiquidacion = () => {
        setShowModalRegistrarLiquidacion(false);
    }

    const handleShowModalEditarLiquidacion = (rowData) => {
        setRowDataLiquidacion(rowData);
        setShowModalEditarLiquidacion(true);
    }

    const handleCloseModalEditarLiquidacion = () => {
        setShowModalEditarLiquidacion(false);
    }

    const handleRefetch = () =>{
        refetch();
    }

    async function mostrarLiquidaciones() {
        try {
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_select/MostrarLiquidaciones.php');
            const data = await response.json();
    
            if (data.success) {
                return data.liquidacion.map(item => {
                    let conceptoSueldoComision = JSON.parse(item.conceptosSueldoComision);
                    let conceptosDineroEntregado = JSON.parse(item.conceptosDineroEntregado);
                    let conceptoGastos = JSON.parse(item.conceptoGastos);
                    let conceptosCuentasOperador = JSON.parse(item.conceptosCuentasOperador);

                    let extra = conceptoSueldoComision.camposAdicionalesSueldoComision;
                    let cargasCombinadas = conceptoSueldoComision.cargaSueldoComision;
                    extra.forEach((adicional) => {
                        cargasCombinadas += ` ${adicional.Carga}`;
                    });

                    return {
                        ...item,
                        fecha_liq: item.fecha_liq.split('T')[0],
                        conceptoSueldoComision: conceptoSueldoComision,
                        conceptosDineroEntregado: conceptosDineroEntregado,
                        conceptoGastos: conceptoGastos,
                        conceptosCuentasOperador: conceptosCuentasOperador,
                        cargasCombinadas: cargasCombinadas
                    };
                });
            } else {
                return [];
                // console.log("ERROR AL SELECCIONAR LIQUIDACIONES");
            }
        } catch (error) {
            console.error('Error al conectar con el servidor:', error);
            return [];
        }
    }

    const crearLiquidacionGeneral = async (rowData) => {
        <Toaster position="top-right" reverseOrder={false} />
        try {
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_insert/InsertarLiquidacionGeneral.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    nLiquidacion: rowData.nLiquidacion, nombreOp: rowData.nombreOp, tracto: rowData.tracto, caja: rowData.caja, sueldoComision: JSON.stringify(rowData.conceptoSueldoComision), porcentajeSueldoComision: rowData.porcentajeSueldoComision
                })
            });

            const data = await response.json();

            if (data.success) {
                toast.success("LIQUIDACION GENERAL #"+rowData.nLiquidacion+ " REGISTRADA CORRECTAMENTE", {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
            } else {
                toast.error(data.error, {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
                // console.log("ERROR AL REGISTRAR LA NUEVA LIQUIDACION GENERAL");
            }

        } catch(error) {
            console.error('Error al conectar con el servidor:', error);
        }
        
    };

    const imprimirLiquidacion = async (rowData) => {
        try {
            const url = 'https://saulsanchezcelis.com/saulsanchez/api/imprimirLiquidacion.php?liquidacionOperador=' + rowData.nLiquidacion;
            window.location.href = url;

            toast.success("LIQUIDACION #"+rowData.nLiquidacion+ " DESCARGADA CORRECTAMENTE", {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });

        } catch(error) {
            console.error('Error al conectar con el servidor:', error);
        }
    }

    const accionBoton = (rowData) => {
        let contenido = [
            <Dropdown.Item key = "detalles" onClick={() => handleShowModalDetalles(rowData)}><i><FontAwesomeIcon icon={faList} /></i> DETALLES</Dropdown.Item>
        ];
        if (permisos.editar && rowData.estatus !== "PAGADO"){
            contenido.push(<Dropdown.Item key = "pagar" onClick={() => handleShowModalPagar(rowData)}><i><FontAwesomeIcon icon={faDollarSign} /></i> PAGAR</Dropdown.Item>);
            contenido.push(<Dropdown.Item key = "editar" onClick={() => handleShowModalEditarLiquidacion(rowData)}><i><FontAwesomeIcon icon={faPenToSquare} /></i> EDITAR</Dropdown.Item>);
        }
        if (permisos.crear){
            contenido.push(<Dropdown.Item key = "liquidaciongeneral" onClick={() => crearLiquidacionGeneral(rowData)}><i><FontAwesomeIcon icon={faFile} /></i> LIQUIDACION GENERAL</Dropdown.Item>);
        }
        contenido.push(<Dropdown.Item key = "imprimir" onClick={() => imprimirLiquidacion(rowData)}><i><FontAwesomeIcon icon={faPrint} /></i> IMPRIMIR</Dropdown.Item>);
        if (permisos.borrar && rowData.estatus !== "PAGADO"){
            contenido.push(<Dropdown.Item key = "borrar" onClick={() => handleShowModalEliminar(rowData)}><i><FontAwesomeIcon icon={faTrash} /></i> BORRAR</Dropdown.Item>);
        }
        return( 
            <Dropdown drop="left">
                <Dropdown.Toggle className = "btnAccion" variant="success" id="dropdown-basic">
                    <i><FontAwesomeIcon icon={faGear}/></i>
                </Dropdown.Toggle>
        
                <Dropdown.Menu>
                    {contenido}
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    const header = (
        <Col md={3}>
            <Form.Control type='search' onInput={(e) => setGlobalFilter(e.target.value)} placeholder="BUSCAR..."></Form.Control>
        </Col>
    );

    const inicioDestino = (rowData) => {
        const conceptos = rowData.conceptoSueldoComision;
        const camposExtra = conceptos.camposAdicionalesSueldoComision;
        return (
            <div>
                <span>{conceptos.inicioSueldoComision} - {conceptos.destinoSueldoComision}</span>
                {camposExtra.map((campo, index) => (
                    <div key={index}>
                        <span>{campo.Inicio} - {campo.Destino}</span>
                    </div>
                ))}
            </div>
        );
    };

    const cargas = (rowData) => {
        const carga = rowData.conceptoSueldoComision;
        const cargaExtra = carga.camposAdicionalesSueldoComision;
        return(
            <div>
                <span>{carga.cargaSueldoComision}</span>
                {cargaExtra.map((cargaExtra, index) => (
                    <div key={index}>
                        <span>{cargaExtra.Carga}</span>
                    </div>
                ))}
            </div>
        );

    };

    const estatus = (rowData) => {
        const estatus = rowData.estatus;
        return (
            <div>
                {
                    estatus === "PAGADO" ? (
                        <span style={{ background: 'green', color: 'white', borderRadius: '5px', fontWeight: 'bold', padding: '2px' }}>PAGADO</span>
                    ) : estatus === "NO PAGADO" ? (
                        <span style={{ background: 'red', color: 'white', borderRadius: '5px', fontWeight: 'bold', padding: '2px' }}>NO PAGADO</span>
                    ) : (
                        <span></span>
                    )
                }
            </div>
        );
    };

    return (
        <div>
            <Sliderbar/>
            <section className="home-section">
                <div className="home-content">
                    <i className='bx bx-menu'></i>
                </div>
                <h4 className='centered'>LIQUIDACION DE OPERADOR</h4>
                {permisos.crear ? <Button className='buttonNew' variant="primary" onClick={() => handleShowModalRegistrarLiquidacion()}><i><FontAwesomeIcon icon={faPen} /></i> NUEVO</Button> : <Button className='botton-hidden'/>}
                {permisos.crear && <RegistrarLiquidacion show={showModalRegistrarLiquidacion} handleClose={handleCloseModalRegistrarLiquidacion} handleRefetch={handleRefetch}/>}
                {
                    isLoading ?
                    <div className='centered'>
                        <div className="spinner"></div>
                        <span><br />CARGANDO LIQUIDACIONES...</span>
                    </div>
                    :
                    <DataTable value={liquidacionesData} dataKey="nLiquidacion" stripedRows showGridlines paginator rows={10} rowsPerPageOptions={[20, 30, 40]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} a {last} de {totalRecords}" globalFilter={globalFilter} header={header} emptyMessage="SIN LIQUIDACIONES REGISTRADOS.">
                        <Column field = "nLiquidacion" header="#LIQ" style={{ minWidth: '1px' }} sortable></Column>
                        <Column field = "fecha_liq" header="FECHA" style={{ minWidth: '25px' }} sortable></Column>
                        <Column field = "nombreOp" header="OPERADOR" style={{ minWidth: '25px' }} sortable></Column>
                        <Column field = "tracto" header="TRACTO" style={{ minWidth: '10px' }} sortable></Column>
                        <Column field = "caja" header="CAJA" style={{ minWidth: '10px' }} sortable></Column>
                        <Column field = "conceptosSueldoComision" header="INICIO - DESTINO" body = {inicioDestino} style={{ minWidth: '15px' }} sortable></Column>
                        <Column field = "cargasCombinadas" header="CARGAS" body = {cargas} style={{ minWidth: '10px' }} sortable></Column>
                        <Column field = "estatus" header="ESTATUS" body = {estatus} style={{ minWidth: '25px' }} sortable></Column>
                        <Column header = "ACCION" body={accionBoton} style={{ minWidth: '15px' }}/>
                    </DataTable>
                }
            </section>
            {permisos.editar && <EditarLiquidacion show={showModalEditarLiquidacion} handleClose={handleCloseModalEditarLiquidacion} handleRefetch={handleRefetch} rowData={rowData}/>}
            <DetallesModal show={showModalDetalles} handleClose={handleCloseModalDetalles} rowData={rowData} />
            {permisos.editar && <PagarModal show={showModalPagar} handleClose={handleCloseModalPagar} handleRefetch = {handleRefetch} rowData={rowData} />}
            {permisos.borrar && <EliminarModal show={showModalEliminar} handleClose={handleCloseModalEliminar} handleRefetch={handleRefetch} rowData={rowData} tipo={"liquidacion"}/>}
        </div>
    );
}

export default Liquidacion;
