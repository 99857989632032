//DIESEL

import { updateGastosGeneralesEnLaVuelta } from "./cuentasGenerales";

export function updateTotalDieselEditar(){
    var dieselTracto = +document.getElementById("tractoDieselEditar").value;
    var dieselThermo = +document.getElementById("thermoDieselEditar").value;
    var precioDiesel = document.getElementById("precioDieselEditar").value;
    document.getElementById("totalPrecioDieselEditar").value = ((dieselTracto + dieselThermo) * precioDiesel).toFixed(3);
    document.getElementById("tractoKmDieselEditar").value = dieselTracto;
    updateKmLtDieselEditar();
}

export function updateKmLtDieselEditar(){
    var kmsTracto = document.getElementById("tractoKmEditar").value;
    var dieselTracto = document.getElementById("tractoDieselEditar").value;
    document.getElementById("totalKmLtDieselEditar").value = (kmsTracto / dieselTracto).toFixed(3);
    updateGastosGeneralesEnLaVuelta();
}

export function getInformacionDiesel(){
    var tractoDiesel = document.getElementById("tractoDieselEditar").value;
    var thermoDiesel = document.getElementById("thermoDieselEditar").value;
    var precioDiesel = document.getElementById("precioDieselEditar").value;
    var totalPrecioDiesel = document.getElementById("totalPrecioDieselEditar").value;

    var tractoKm = document.getElementById("tractoKmEditar").value;
    var totalKmLtDiesel = document.getElementById("totalKmLtDieselEditar").value;

    // if (tractoDiesel === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA CANTIDAD DE DIESEL DEL TRACTO"
    //     };
    // }

    // if (thermoDiesel === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA CANTIDAD DE DIESEL DEL THERMO"
    //     };
    // }

    // if (precioDiesel === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE EL PRECIO DEL DIESEL"
    //     };
    // }

    // if (totalPrecioDiesel === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE EL TOTAL DEL PRECIO DEL DIESEL"
    //     };
    // }

    // if (tractoKm === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LOS KMS DEL TRACO"
    //     };
    // }

    // if (totalKmLtDiesel === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE EL TOTAL DEL KMS/LTS"
    //     };
    // }



    var informacionDiesel = {
        tractoDiesel: tractoDiesel,
        thermoDiesel: thermoDiesel,
        precioDiesel: precioDiesel,
        totalPrecioDiesel: totalPrecioDiesel,
        tractoKm: tractoKm,
        totalKmLtDiesel: totalKmLtDiesel
    }

    return informacionDiesel;
}