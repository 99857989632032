// ACTUALIZAR
export function updateSubTotalSueldoComisionEditar() {
    var subTotalSueldoComisionEditar = 0;

    //COMISION
    var comisionSueldoComisionEditar = 0;
    var comisionDefaultAmountEditar = document.getElementById("comisionSueldoComisionEditar").value;
    comisionSueldoComisionEditar += parseFloat(comisionDefaultAmountEditar) || 0;

    //BARCO
    var barcoSueldoComisionEditar = 0;
    var barcoDefaultAmountEditar = document.getElementById("barcoSueldoComisionEditar").value;
    barcoSueldoComisionEditar += parseFloat(barcoDefaultAmountEditar) || 0;

    //TRANSFER
    var transferSueldoComisionEditar = 0;
    var transferDefaultAmountEditar = document.getElementById("transferSueldoComisionEditar").value;
    transferSueldoComisionEditar += parseFloat(transferDefaultAmountEditar) || 0;

    //Flete
    var fleteSueldoComisionEditar = 0;
    var fleteDefaultAmountEditar = document.getElementById("amountSueldoComisionEditar").value;
    fleteSueldoComisionEditar += parseFloat(fleteDefaultAmountEditar) || 0;

    var fechaSueldoComisionEditar = document.querySelectorAll("#extraFieldsSueldoComisionEditar input[type='date']");
    var amountsSueldoComisionEditar = document.querySelectorAll("#extraFieldsSueldoComisionEditar input[type='number']");
    for (let i = 0; i < fechaSueldoComisionEditar.length; i++) {
        var posComisionEditar = i * 4;
        var posBarcoEditar = i * 4 + 1;
        var posTransferEditar = i * 4 + 2;
        var posFleteEditar = i * 4 + 3;
        
        comisionSueldoComisionEditar += parseFloat(amountsSueldoComisionEditar[posComisionEditar].value) || 0;
        barcoSueldoComisionEditar += parseFloat(amountsSueldoComisionEditar[posBarcoEditar].value) || 0;
        transferSueldoComisionEditar += parseFloat(amountsSueldoComisionEditar[posTransferEditar].value) || 0;
        fleteSueldoComisionEditar += parseFloat(amountsSueldoComisionEditar[posFleteEditar].value) || 0;

    }
    subTotalSueldoComisionEditar = fleteSueldoComisionEditar - comisionSueldoComisionEditar - transferSueldoComisionEditar - barcoSueldoComisionEditar;
    document.getElementById("subTotalAmountSueldoComisionEditar").value = subTotalSueldoComisionEditar.toFixed(3);

    porcentajeSueldoComisionEditar();
}

export function porcentajeSueldoComisionEditar(){
    var subTotal = document.getElementById("subTotalAmountSueldoComisionEditar").value;
    var porcentaje = document.getElementById("porcentajeAmountSueldoComisionEditar").value;

    var resultado = (subTotal * (porcentaje / 100)).toFixed(3);
    document.getElementById("totalAmountSueldoComisionEditar").value = resultado;

    //CUENTAS OPERADOR
    document.getElementById("amountCuentasOperador4Editar").value = resultado;

    var sobroAbonoASuSueldoEditar = document.getElementById("amountCuentasOperador3Editar").value;
    var sueldoComisionTotalEditar = document.getElementById("amountCuentasOperador4Editar").value;
    var totalExtrasCuentasOperadorEditar = 0;
    var cantidadesExtrasCuentasOperadorEditar = document.querySelectorAll("#extraFieldsCuentasOperadorEditar input[type='number']");
    cantidadesExtrasCuentasOperadorEditar.forEach(function(amountInputCuentasOperadorEditar) {
        totalExtrasCuentasOperadorEditar += parseFloat(amountInputCuentasOperadorEditar.value) || 0;
    });

    document.getElementById("totalAmountCuentasOperadorEditar").value = (sueldoComisionTotalEditar - sobroAbonoASuSueldoEditar - totalExtrasCuentasOperadorEditar).toFixed(3);

}

var camposAdicionalesSueldoComision = [];
export function getInformacionSueldoComisionEditar(){
    var fechaSueldoComision = document.getElementById("fechaSueldoComisionEditar").value;
    var inicioSueldoComision = document.getElementById("inicioSueldoComisionEditar").value;
    var destinoSueldoComision = document.getElementById("destinoSueldoComisionEditar").value;
    var cargaSueldoComision = document.getElementById("cargaSueldoComisionEditar").value;
    var comisionSueldoComision = document.getElementById("comisionSueldoComisionEditar").value;
    var barcoSueldoComision = document.getElementById("barcoSueldoComisionEditar").value;
    var transferSueldoComision = document.getElementById("transferSueldoComisionEditar").value;
    var amountSueldoComision = document.getElementById("amountSueldoComisionEditar").value;
    var totalSueldoComision = document.getElementById("totalAmountSueldoComisionEditar").value;

    var fechaSueldoComisionExtras = document.querySelectorAll("#extraFieldsSueldoComisionEditar input[type='date']");
    var camposExtrasSueldoComision = document.querySelectorAll("#extraFieldsSueldoComisionEditar input[type='text']");
    var camposExtrasCantidadComision = document.querySelectorAll("#extraFieldsSueldoComisionEditar input[type='number']");

    var campoExtraFechaSueldoComision = "";
    var campoComisionSueldoComision = 0;
    var campoBarcoSueldoComision = 0;
    var campoTransferSueldoComision = 0;
    var campoCantidadSueldoComision = 0;
    var campoExtraInicioSueldoComision = "";
    var campoExtraDestinoSueldoComision = "";
    var campoCargaSueldoComision = "";

    camposAdicionalesSueldoComision.length = 0;
    for (let i = 0; i < fechaSueldoComisionExtras.length; i++) {
        campoExtraFechaSueldoComision = fechaSueldoComisionExtras[i].value;

        var posComision = i * 4;
        var posBarco = i * 4 + 1;
        var posTransfer = i * 4 + 2;
        var posFlete = i * 4 + 3;

        campoComisionSueldoComision = camposExtrasCantidadComision[posComision].value;
        campoBarcoSueldoComision = camposExtrasCantidadComision[posBarco].value;
        campoTransferSueldoComision = camposExtrasCantidadComision[posTransfer].value;
        campoCantidadSueldoComision = camposExtrasCantidadComision[posFlete].value;
    
        var indexInicio = i * 3;
        var indexDestino = i * 3 + 1;
        var indexCarga = i * 3 + 2;
    
        campoExtraInicioSueldoComision = camposExtrasSueldoComision[indexInicio].value;
        campoExtraDestinoSueldoComision = camposExtrasSueldoComision[indexDestino].value;
        campoCargaSueldoComision = camposExtrasSueldoComision[indexCarga].value;
    
        camposAdicionalesSueldoComision.push({ 
            Fecha: campoExtraFechaSueldoComision, 
            Inicio: campoExtraInicioSueldoComision, 
            Destino: campoExtraDestinoSueldoComision, 
            Carga: campoCargaSueldoComision,
            Comision: campoComisionSueldoComision,
            Barco: campoBarcoSueldoComision,
            Transfer: campoTransferSueldoComision,
            Cantidad: campoCantidadSueldoComision
        });
    }

    // if (fechaSueldoComision === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA FECHA N.1 DE SUELDO-COMISION"
    //     };
    // }

    // if (inicioSueldoComision === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE EL INICIO N.1 DE SUELDO-COMISION",
    //     };
    // }

    // if (destinoSueldoComision === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE EL DESTINO N.1 DE SUELDO-COMISION",
    //     };
    // }

    // if (cargaSueldoComision === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA CARGA N.1 DE SUELDO-COMISION",
    //     };
    // }

    // if (amountSueldoComision === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA CANTIDAD DE FLETE N.1 de SUELDO-COMISION",
    //     };
    // }

    // for (let i = 0; i < fechaSueldoComisionExtras.length; i++) {    
    //     let indexInicio = i * 3;
    //     let indexDestino = i * 3 + 1;
    //     let indexCarga = i * 3 + 2;
    //     let posFlete = i * 4 + 3;

    //     if (fechaSueldoComisionExtras[i].value === ""){
    //         return {
    //             success: false,
    //             error: "INGRESE LA FECHA N."+(i+2)+" DE SUELDO-COMISION"
    //         };
    //     }

    //     if (camposExtrasSueldoComision[indexInicio].value === ""){
    //         return {
    //             success: false,
    //             error: "INGRESE EL INICIO N."+(indexInicio+2)+" DE SUELDO-COMISION"
    //         };
    //     }

    //     if (camposExtrasSueldoComision[indexDestino].value === ""){
    //         return {
    //             success: false,
    //             error: "INGRESE EL DESTINO N."+(indexDestino+1)+" DE SUELDO-COMISION"
    //         };
    //     }

    //     if (camposExtrasSueldoComision[indexCarga].value === ""){
    //         return {
    //             success: false,
    //             error: "INGRESE LA CARGA N."+(indexCarga)+" DE SUELDO-COMISION"
    //         };
    //     }

    //     if (camposExtrasCantidadComision[posFlete].value === ""){
    //         return {
    //             success: false,
    //             error: "INGRESE LA CANTIDAD N."+(i+2)+" DE SUELDO-COMISION"
    //         };
    //     }

    // }

    var informacionSueldoComision = {
        fechaSueldoComision: fechaSueldoComision,
        inicioSueldoComision: inicioSueldoComision,
        destinoSueldoComision: destinoSueldoComision,
        cargaSueldoComision: cargaSueldoComision,
        comisionSueldoComision: comisionSueldoComision,
        barcoSueldoComision: barcoSueldoComision,
        transferSueldoComision: transferSueldoComision,
        amountSueldoComision: amountSueldoComision,
        camposAdicionalesSueldoComision: camposAdicionalesSueldoComision,
        totalSueldoComision: totalSueldoComision
    };

    return informacionSueldoComision;
}