//DINERO ENTREGADO AL OPERADOR
export function updateTotalDineroEntregado() {
    var totalDineroEntregado = 0;
    var defaultAmount = document.getElementById("amountDineroEntregado").value;
    totalDineroEntregado += parseFloat(defaultAmount) || 0;
    var amounts = document.querySelectorAll("#extraFieldsDineroEntregado input[type='number']");
    amounts.forEach(function(amountInput) {
        totalDineroEntregado += parseFloat(amountInput.value) || 0;
    });
    document.getElementById("totalAmountDineroEntregado").value = totalDineroEntregado;

    //CUENTAS CON EL OPERADOR
    document.getElementById("amountCuentasOperador1").value = totalDineroEntregado;

    var dineroEntregadoOperador = document.getElementById("totalAmountDineroEntregado").value;
    var gastosQueHizoOperador = document.getElementById("totalAmountGastos").value;
    document.getElementById("amountCuentasOperador3").value = dineroEntregadoOperador - gastosQueHizoOperador; 

    var sobroAbonoASuSueldo = document.getElementById("amountCuentasOperador3").value; 
    var sueldoComisionTotal = document.getElementById("amountCuentasOperador4").value; 
    var totalExtrasCuentasOperador = 0;
    var cantidadesExtrasCuentasOperador = document.querySelectorAll("#extraFieldsCuentasOperador input[type='number']");
    cantidadesExtrasCuentasOperador.forEach(function(amountInputCuentasOperador) {
        totalExtrasCuentasOperador += parseFloat(amountInputCuentasOperador.value) || 0;
    });

    document.getElementById("totalAmountCuentasOperador").value = sueldoComisionTotal - sobroAbonoASuSueldo - totalExtrasCuentasOperador;
}

var camposAdicionalesDineroEntregado = [];

export function getInformacionDineroEntregado(){
    var fechaDineroEntregado = document.getElementById("fechaDineroEntregado").value;
    var descriptionDineroEntregado = document.getElementById("descriptionDineroEntregado").value;
    var amountDineroEntregado = document.getElementById("amountDineroEntregado").value;
    var totalDineroEntregado = document.getElementById("totalAmountDineroEntregado").value;

    var fechaDineroEntregadoExtras = document.querySelectorAll("#extraFieldsDineroEntregado input[type='date']");
    var descripcionDineroEntregadoExtras = document.querySelectorAll("#extraFieldsDineroEntregado input[type='text']");
    var cantidadDineroEntregadoExtras = document.querySelectorAll("#extraFieldsDineroEntregado input[type='number']");

    camposAdicionalesDineroEntregado.length = 0;

    for (var i = 0; i < fechaDineroEntregadoExtras.length; i++) {
        var fechaDineroEntregadoExtra = fechaDineroEntregadoExtras[i].value;
        var descripcionDineroEntregadoExtra = descripcionDineroEntregadoExtras[i].value;
        var cantidadDineroEntregadoExtra = cantidadDineroEntregadoExtras[i].value;

        camposAdicionalesDineroEntregado.push({ Fecha: fechaDineroEntregadoExtra, Descripcion: descripcionDineroEntregadoExtra, Cantidad: cantidadDineroEntregadoExtra });
    }

    // if (fechaDineroEntregado === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA FECHA N.1 DE DINERO ENTREGADO"
    //     };
    // }

    // if (descriptionDineroEntregado === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA DESCRIPCION N.1 DE DINERO ENTREGADO"
    //     };
    // }

    // if (amountDineroEntregado === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA CANTIDAD N.1 DE DINERO ENTREGADO"
    //     };
    // }

    // for (let i = 0; i < fechaDineroEntregadoExtras.length; i++) {
    //     if (fechaDineroEntregadoExtras[i].value === "") {
    //         return {
    //             success: false,
    //             error: "INGRESE LA FECHA N."+(i+2)+" DE DINERO ENTREGADO"
    //         };
    //     }

    //     if (descripcionDineroEntregadoExtras[i].value === "") {
    //         return {
    //             success: false,
    //             error: "INGRESE LA DESCRIPCION N."+(i+2)+" DE DINERO ENTREGADO"
    //         };
    //     }

    //     if (cantidadDineroEntregadoExtras[i].value === "") {
    //         return {
    //             success: false,
    //             error: "INGRESE LA CANTIDAD N."+(i+2)+" DE DINERO ENTREGADO"
    //         };
    //     }
        
    // }

    var informacionDineroEntregado = {
        fechaDineroEntregado: fechaDineroEntregado,
        descriptionDineroEntregado: descriptionDineroEntregado,
        amountDineroEntregado: amountDineroEntregado,
        camposAdicionalesDineroEntregado: camposAdicionalesDineroEntregado,
        totalDineroEntregado: totalDineroEntregado
    };

    return informacionDineroEntregado;
}