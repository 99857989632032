//DINERO ENTREGADO AL OPERADOR
export function updateTotalDineroEntregadoEditar() {
    var totalDineroEntregadoEditar = 0;
    var defaultAmountEditar = document.getElementById("amountDineroEntregadoEditar").value;
    totalDineroEntregadoEditar += parseFloat(defaultAmountEditar) || 0;
    var amountsEditar = document.querySelectorAll("#extraFieldsDineroEntregadoEditar input[type='number']");
    amountsEditar.forEach(function(amountInputEditar) {
        totalDineroEntregadoEditar += parseFloat(amountInputEditar.value) || 0;
    });
    document.getElementById("totalAmountDineroEntregadoEditar").value = totalDineroEntregadoEditar;

    //CUENTAS CON EL OPERADOR
    document.getElementById("amountCuentasOperador1Editar").value = totalDineroEntregadoEditar;

    var dineroEntregadoOperadorEditar = document.getElementById("totalAmountDineroEntregadoEditar").value;
    var gastosQueHizoOperadorEditar = document.getElementById("totalAmountGastosEditar").value;
    document.getElementById("amountCuentasOperador3Editar").value = dineroEntregadoOperadorEditar - gastosQueHizoOperadorEditar; 

    var sobroAbonoASuSueldoEditar = document.getElementById("amountCuentasOperador3Editar").value; 
    var sueldoComisionTotalEditar = document.getElementById("amountCuentasOperador4Editar").value; 
    var totalExtrasCuentasOperadorEditar = 0;
    var cantidadesExtrasCuentasOperadorEditar = document.querySelectorAll("#extraFieldsCuentasOperadorEditar input[type='number']");
    cantidadesExtrasCuentasOperadorEditar.forEach(function(amountInputCuentasOperadorEditar) {
        totalExtrasCuentasOperadorEditar += parseFloat(amountInputCuentasOperadorEditar.value) || 0;
    });

    document.getElementById("totalAmountCuentasOperadorEditar").value = sueldoComisionTotalEditar - sobroAbonoASuSueldoEditar - totalExtrasCuentasOperadorEditar;
}

var camposAdicionalesDineroEntregado = []
export function getInformacionDineroEntregadoEditar(){
    var fechaDineroEntregado = document.getElementById("fechaDineroEntregadoEditar").value;
    var descriptionDineroEntregado = document.getElementById("descriptionDineroEntregadoEditar").value;
    var amountDineroEntregado = document.getElementById("amountDineroEntregadoEditar").value;
    var totalDineroEntregado = document.getElementById("totalAmountDineroEntregadoEditar").value;

    var fechaDineroEntregadoExtras = document.querySelectorAll("#extraFieldsDineroEntregadoEditar input[type='date']");
    var descripcionDineroEntregadoExtras = document.querySelectorAll("#extraFieldsDineroEntregadoEditar input[type='text']");
    var cantidadDineroEntregadoExtras = document.querySelectorAll("#extraFieldsDineroEntregadoEditar input[type='number']");

    camposAdicionalesDineroEntregado.length = 0;

    for (var i = 0; i < fechaDineroEntregadoExtras.length; i++) {
        var fechaDineroEntregadoExtra = fechaDineroEntregadoExtras[i].value;
        var descripcionDineroEntregadoExtra = descripcionDineroEntregadoExtras[i].value;
        var cantidadDineroEntregadoExtra = cantidadDineroEntregadoExtras[i].value;

        camposAdicionalesDineroEntregado.push({ Fecha: fechaDineroEntregadoExtra, Descripcion: descripcionDineroEntregadoExtra, Cantidad: cantidadDineroEntregadoExtra });
    }

    // if (fechaDineroEntregado === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA FECHA N.1 DE DINERO ENTREGADO"
    //     };
    // }

    // if (descriptionDineroEntregado === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA DESCRIPCION N.1 DE DINERO ENTREGADO"
    //     };
    // }

    // if (amountDineroEntregado === "") {
    //     return {
    //         success: false,
    //         error: "INGRESE LA CANTIDAD N.1 DE DINERO ENTREGADO"
    //     };
    // }

    // for (let i = 0; i < fechaDineroEntregadoExtras.length; i++) {
    //     if (fechaDineroEntregadoExtras[i].value === "") {
    //         return {
    //             success: false,
    //             error: "INGRESE LA FECHA N."+(i+2)+" DE DINERO ENTREGADO"
    //         };
    //     }

    //     if (descripcionDineroEntregadoExtras[i].value === "") {
    //         return {
    //             success: false,
    //             error: "INGRESE LA DESCRIPCION N."+(i+2)+" DE DINERO ENTREGADO"
    //         };
    //     }

    //     if (cantidadDineroEntregadoExtras[i].value === "") {
    //         return {
    //             success: false,
    //             error: "INGRESE LA CANTIDAD N."+(i+2)+" DE DINERO ENTREGADO"
    //         };
    //     }
        
    // }

    var informacionDineroEntregado = {
        fechaDineroEntregado: fechaDineroEntregado,
        descriptionDineroEntregado: descriptionDineroEntregado,
        amountDineroEntregado: amountDineroEntregado,
        camposAdicionalesDineroEntregado: camposAdicionalesDineroEntregado,
        totalDineroEntregado: totalDineroEntregado
    };

    return informacionDineroEntregado;
}